import { Component, ViewChild,  Renderer2 } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { NgForm } from "@angular/forms";
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbDatepickerConfig, NgbModalRef, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Title, Meta } from '@angular/platform-browser';
import { NgbRatingModule } from '@ng-bootstrap/ng-bootstrap';
import { RestService } from "../../services/rest.service";
import { AuthService } from "../../services/auth.service";
import { CustomstorageService } from "../../services/customstorage.service";
import { environment } from '../../../environments/environment';
import { CartModel } from '../../model/cartmodel.model';
import { OptionModel } from '../../model/optionmodel.model';
import { SubOptionModel } from '../../model/suboptionmodel.model';
import { CustomEventService } from '../../services/custom-event.service';
// import confetti from 'canvas-confetti';

declare var $: any;
@Component({
  selector: 'app-business',
  templateUrl: './business.component.html',
  styleUrl: './business.component.scss'
})
export class BusinessComponent {

  @ViewChild("closeRest") closeRest: any;
  @ViewChild("deliverymodal") deliverymodal: any;
  @ViewChild("zipcodemodal") zipcodemodal: any;
  @ViewChild("extrapopup") extrapopup: any;
  @ViewChild("freedishoptionpopup") freedishoptionpopup: any;
  @ViewChild("bookingMain") bookingMain: NgForm = <any>"";



  public temp_prod_obj = {
    temp_sub_product: <any>"",
    temp_main_product: <any>"",
    temp_type_product: <any>"",
    productOptionPayload: <any>"",
    temp_type: <any>""
  }

  public base_url = environment.baseUrl;
  public payment_url = environment.payment_url;

  public reviewMinLoader = true;
  public bookingshowdloader = false;
  public infoMinLoader = false;
  public fullpageLoader = false;
  public menuLoader = true;
  
  // for review
  public user_review: any = [];
  public bs_name = '';
  public overall_rating = 0;
  public overall_rating_text = "";
  public avg_noise_rating = 0;
  public avg_service_rating = 0;
  public avg_food_rating = 0;
  public avg_atmosphere_rating = 0;
  public avg_waittime_rating = 0;
  public avg_valueformoney_rating = 0;
  public total_review_no = 0;
  public review_load = true;
  public req_page = 0;
  public overallreq_data = true;
  public more_review_status = false;
  public reviewEmptyMsg = '';
  public authtoken: any;
  public logfalse = false;
  public applied_discount: any;
  public coupon_type = "";
  public manual_coupon_text = "Apply Discount Code";
  public manual_coupon_code_input = "";
  public couponeror = "";
  public nolader = true;
  public booking_agree_checkbox = false;
  public BookingMobileCartHideShow = false;
  public booking: any;
  public booking_payment_accept_over_guest_number = 0;
  public every_booking_service_charge_type = "fixed";
  public every_booking_service_charge_price = 0;
  public total_booking_service_charge_price = 0;
  public every_person_price = 0;
  public every_booking_price = 0;
  public total_booking_price = 0;
  public total_booking_person_price = 0;
  public booking_payment_permission: any = [];

  public bookingPayModeErr = false;
  public bookingSubmitBtnClick = ""
  public bookingdescription = '';
  booking_button_label = "";

  public special_booking_note = "";
  public special_booking_note_temp = "";
  public special_booking_note_read = "less";
  public booking_terms_condition_accept = ""
  public booking_date_label = ""
  public timesloarray: any = [];
  public booking_payload = {
    sessiontoken: "",
    business_id: 0,
    date: <any>"",
    time: "",
    time_slot_id: 0,
    paymode: "",
    guest: "",
    total_guest_price: 0,
    name: "",
    email: "",
    phone: "",
    pincode: "",
    about_us: "about_us",
    occasion: "",
    message: "",
    booking_update_sms: "y",
    bookyup_offers: "y",
    restaurant_offers: ""
  };

  public option_current_data = {
    'is_sub': <any>'',
    'mainobj': <any>'',
    'subobj': <any>'',
  }
  public optionModalErrorMsg = '';
  public optionPopData:any =[];
  public displayTotal = 0;
  public max_person = 10;
  public min_person = 1;

  // for info
  public info_load = true;
  public info_data = {
    bs_name: "",
    bs_opening_schedule: <any>[],
    bs_delivery_sectors: "",
    bs_details: "",
    bs_special_openclose_schedule: <any>[]
  }
  public search: any = {
    searchmode: false,
    serchtxt: '',
    searchresult: []
  }
  public isTempProduct = false;
  public tempOptionPrice = 0;
  public tempOptionValidation:any = [];



  public menuCatagoryShow = false;
  public dishes: any;
  public discount: any;
  public default_discount: any = [];
  public discount_delivery_charge_waiver: any;
  public discount_delivery_charge_waiver_short: string = '';
  public opendivid = 0;

  public businessId = 0;
  public business_logo = 'https://api.bookyup.co.uk/assets/uploads/business/logo/default-logo.png';
  public business_name = '';
  public business_address = '';
  public business_colony = '';
  public business_city = '';
  public pincode = "";
  public slug = "";
  public category: any = [];
  public subcategory = "";
  public minorderamnt = 0;
  total_discount: number = 0.0;
  additionalchageamnt = 0.0;
  servicecharge = 0.0;
  baggagecharge = 0.0;
  public formbody = {
    zipcode: "",
    slug: ""
  };
  public totalpayble = 0;
  public pickup_totalfordiscount = 0;
  public delivery_totalfordiscount = 0;
  public validzipcodetext = "";
  public selectedZip: any;
  public zone_delivery_charg = 0.0;
  public delivery_charge_waiver = 0.0;
  public discounted_item_count = 0;
  public business_meta_details: any = {};
  public delpossible = true;
  public pickuppossible = true;
  public pickupmsg = "";
  public deliverymsg = "";
  public selected_tab = "menu";
  public hideShowMobileCart = "";

  public buisnessdata = <any>"";
  public deliverytype = <String>"";
  public deliverytypeoption = <String>"";
  public isDeliverySelected: boolean = <boolean>false;
  public cartitems: CartModel[] = [];
  public tempcart: CartModel[] = [];
  // public bannerUrl = "https://api.bookyup.co.uk/assets/uploads/business/cover/default-banner.png";
  public bannerUrl = "../assets/images/banner/listing1.png";
  public freeDishSurpriseOn = false;
  public freedishWelcomeTextArr: any[] = [];
  public isCustomizableFreedish = 'no';
  public freeDishAutoPopupOpenArr: any = [];
  public showFreeDishOptionId = 0;
  public appliedfreedish: any = [];
  public appliedfreedishdisplay: any = [];
  public appliedFreeDishId = 0;
  public freedishSaveAmount = 0;
  public showFreeDishPopup = true;
  public bookingForm!: FormGroup;
  public productOptionPayload:any =[];
  public selectedOption:any={};
  constructor(
    public restProvider: RestService,
    public rts: Router,
    public route: ActivatedRoute,
    public storage: CustomstorageService,
    private titleService: Title,
    private metaService: Meta,
    private authService: AuthService,
    public modalService: NgbModal,
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    public customEvent: CustomEventService,
    private renderer: Renderer2
  ) {

    this.customEvent.checklogincomplete().subscribe(ldata => {
      if (this.logfalse) {
        this.logfalse = false;
        this.gotocheckout();
      }
    });
    this.business_meta_details = this.route["snapshot"]["data"]["businessMeta"];
    this.slug = this.route["snapshot"]["params"]["slug"];
    let metaTitle = "";
    let metaKeywords = "";
    let metaDescription = "";
    if (this.business_meta_details.meta_details != undefined && this.business_meta_details.meta_details != null && this.business_meta_details.meta_details.bm_title != undefined && this.business_meta_details.meta_details.bm_title != null) {
      metaTitle = this.business_meta_details.meta_details.bm_title;
    }
    if (this.business_meta_details.meta_details != undefined && this.business_meta_details.meta_details != null && this.business_meta_details.meta_details.bm_keywords != undefined && this.business_meta_details.meta_details.bm_keywords != null) {
      metaKeywords = this.business_meta_details.meta_details.bm_keywords;
    }
    if (this.business_meta_details.meta_details != undefined && this.business_meta_details.meta_details != null && this.business_meta_details.meta_details.bm_description != undefined && this.business_meta_details.meta_details.bm_description != null) {
      metaDescription = this.business_meta_details.meta_details.bm_description;
    }
    this.setSeoNew(metaTitle, metaKeywords, metaDescription);
    // console.log(this.route.snapshot.data.businessMeta);
    customEvent.setBanner(this.route["snapshot"]["data"]["businessMeta"]['business_banner_url']);
    this.cartitems = [];
    this.tempcart = [];
    this.fullpageLoader = true;
    this.showFreeDishPopup = false;
    this.bookingForm = this.formBuilder.group({
      date: new FormControl(null, [Validators.required]),
      time: new FormControl(null, [Validators.required]),
      guest: new FormControl(null, [Validators.required]),
      name: new FormControl(null, [Validators.required]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      phone: new FormControl(null, [Validators.required, Validators.pattern('^0[0-9]{10}$')]),
      pincode: new FormControl(null, [Validators.required, Validators.minLength(5), Validators.maxLength(7)]),
      occasion: new FormControl(null, [Validators.required]),
      message: new FormControl(null, [Validators.required])
    });
    const current = new Date();
    config.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }
  ngOnInit() {
    this.loaddata();
    this.initpage();
    window.scroll(0, 0);
    (<any>$(window)).scroll(function () {
      if ((<any>$(window)).width() < 992) {
        $("#delivery-holder").removeClass("fixed");
        return;
      }
      let cart: any = $("#delivery-holder"),
        scroll_pos = Number($(window).scrollTop()),
        doc_h = Number($(document).height()),
        win_h = Number($(window).height()),
        footer_h = Number($("footer").height());
      if (cart.offset() != undefined) {
        if (scroll_pos > Number(cart.offset().top)) {
          cart.addClass("fixed");
          $("#delivery-holder .holder").width($("#delivery-holder").width() + "px");
        } else {
          cart.removeClass("fixed");
        }
      }
      if (doc_h - win_h - footer_h < scroll_pos) {
        var x = (doc_h - scroll_pos - footer_h - 30) + "px";
        $("#delivery-holder .holder").css("max-height", x);
      } else {
        $("#delivery-holder .holder").css("max-height", "95vh");
      }
      $("#delivery-holder .holder").scrollTop(9999);
    });
    (<any>$(window)).resize(function () {
      if ((<any>$(window)).width() < 992) {
        $("#delivery-holder").removeClass("fixed");
        $("#delivery-holder .holder").width("auto");
      }
    });
  }
  public async initpage() {
    this.slug = this.route["snapshot"]["params"]["slug"];
    let userdata = await this.storage.getLocalstorage("userdata");
    this.deliverytype = await this.storage.getLocalstorage("isDelMode");
    this.minorderamnt = Number(await this.storage.getLocalstorage("minorderamnt"));
    this.zone_delivery_charg = Number(await this.storage.getLocalstorage("zone_delivery_charg"));
    this.delivery_charge_waiver = Number(await this.storage.getLocalstorage("delivery_charge_waiver"));
    let cartlist = await this.storage.getLocalstorage("cartlist");
    if (cartlist == null) {
      this.cartitems = [];
    } else {
      this.cartitems = cartlist;
      // this.showFreeDishPopup = false;
    }
    let t1freeDishAutoPopupOpenArr = await this.storage.getLocalstorage("freeDishAutoPopupOpenArr");
    if (t1freeDishAutoPopupOpenArr != undefined && t1freeDishAutoPopupOpenArr != null && t1freeDishAutoPopupOpenArr != '') {
      this.freeDishAutoPopupOpenArr = t1freeDishAutoPopupOpenArr;
    }
  }
  
  apply_manual_coupon() {
    this.couponeror = "";
    this.nolader = false;
    let coupon_payload = {
      businessid: this.businessId,
      discountcode: this.manual_coupon_code_input,
      productid: <any>'',
      servicetype: this.deliverytype,
    }
    coupon_payload.productid = this.cartitems;
    this.restProvider
      .postdataapi(coupon_payload, "business/manual_discount")
      .subscribe(async (result: any) => {
        this.nolader = true;
        if (result.responsecode == 200) {
          this.total_discount = Number(result.discount_price);
          this.manual_coupon_text = "Remove Coupon";
          this.coupon_type = 'manual';
          this.applied_discount = result.discount;
        } else {
          this.couponeror = result.status;
        }
      }, (error: any) => {
        this.nolader = true;
      });
  }
  show_coupon_box() {
    switch (this.manual_coupon_text) {
      case 'Apply Discount Code': {
        this.manual_coupon_text = "Cancel";
        break;
      }
      case "Cancel": {
        this.manual_coupon_text = "Apply Discount Code";
        this.couponeror = "";
        this.manual_coupon_code_input = "";
        break;
      }
      case "Remove Coupon": {
        this.manual_coupon_text = "Apply Discount Code";
        this.applied_discount = "";
        this.couponeror = "";
        this.total_cal();
        break;
      }
      default: {
        // statements; 
        break;
      }
    }
  }
  loadMoreReview() {
    this.reviewMinLoader = true;
    this.req_page += 1;
    this.getAllReview();
    this.review_load = false;
  }

  async booking_call() {
    console.log(this.booking_payload)
    if (this.bookingForm.invalid) {
      this.bookingForm.controls['date'].markAsTouched();
      this.bookingForm.controls['time'].markAsTouched();
      this.bookingForm.controls['guest'].markAsTouched();
      this.bookingForm.controls['name'].markAsTouched();
      this.bookingForm.controls['pincode'].markAsTouched();
      this.bookingForm.controls['occasion'].markAsTouched();
      this.bookingForm.controls['message'].markAsTouched();
      this.bookingForm.controls['phone'].markAsTouched();
      this.bookingForm.controls['email'].markAsTouched();
      // close the booking popup cart
      let a = $('#mobileBookingCartPopup');
      if (a.hasClass('open')) {
        a.removeClass("open");
      }
      return;
    } else {
      // console.log(frmval);
      // checking paymode available or not
      let paymodeflag = '';
      // console.log(this.booking_payload);
      if (this.booking_payload.paymode.toLowerCase() != '') {
        // console.log(this.booking_payload.paymode.toLowerCase());
        // console.log("test1");
        this.booking_payment_permission.map((x: any) => {
          if (x.value.toLowerCase() == this.booking_payload.paymode.toLowerCase()) {
            paymodeflag = 'yes';
            // console.log("test2");
          }
        });
        if (Number(this.booking_payload.total_guest_price) == 0) {
          paymodeflag = 'yes';
          // we are use paymode as cod
          // this.booking_payload.paymode='cod';
        }
        console.log(this.booking_payload);
        if (paymodeflag != 'yes') {
          // console.log("test3");
          // need to display paymode
          this.booking_payload.paymode = '';
          this.bookingPayModeErr = true;
          return;
        }
      }
      if (this.every_booking_price == 0 && this.every_booking_service_charge_price == 0 && this.every_person_price == 0) {
        // another condition check if person price and booking price and service charge ==0 then paymode set by default cod
        this.booking_payload.paymode = 'cod';
      }

      // if (frmval && (this.booking_agree_checkbox||(this.booking_payload.paymode=='cod' && this.every_booking_price==0 && this.every_booking_service_charge_price==0 && this.every_person_price==0) || (this.every_person_price > 0 && this.booking_payment_accept_over_guest_number > 0))) {

      if ((this.booking_agree_checkbox && (Number(this.booking_payload.total_guest_price) + Number(this.total_booking_service_charge_price)) > 0) || (this.booking_payload.paymode == 'cod' && this.every_booking_price == 0 && this.every_booking_service_charge_price == 0 && this.every_person_price == 0) || (this.booking_payment_accept_over_guest_number > 0 && this.booking_payment_accept_over_guest_number >= Number(this.booking_payload.guest))) {
        if (this.booking_payload.paymode != "" || (this.every_booking_service_charge_price == 0 && this.every_person_price == 0 && this.every_booking_price == 0)) {
          if (this.booking_payload.paymode == "") {
            this.booking_payload.paymode = "cod";
          }
          let userdata = await this.storage.getLocalstorage("userdata");
          if(userdata!=undefined && userdata!=null && userdata.sessiontoken != undefined && userdata.sessiontoken != null){
            this.booking_payload.sessiontoken = userdata.sessiontoken;
          }
          this.bookingshowdloader = true;
          let prevdate = this.booking_payload.date;
          this.booking_payload.date = this.booking_payload.date.year + "-" + this.booking_payload.date.month + "-" + this.booking_payload.date.day;
          this.booking_payload.business_id = this.businessId;
          // console.log('done booking')
          this.restProvider
            .postdataapi(this.booking_payload, "business/booking")
            .subscribe(async (result: any) => {
              // console.log(result);
              this.bookingSubmitBtnClick = 'no';
              setTimeout(() => {
                this.bookingshowdloader = false;
              }, 2500);
              if (result.responsecode == "200") {
                // let booking_token = result.id;
                if (this.booking_payload.paymode == "cod") {
                  window.location.href = this.base_url + "booking-complete?v=" + result.id;
                } else {
                  // console.log(this.payment_url + "pay?key=" + result.id + "_booking&ptype=" + this.booking_payload.paymode);
                  window.location.href = this.payment_url + "pay?key=" + result.id + "_booking&ptype=" + this.booking_payload.paymode;
                }
              } else {
                this.customEvent.opencommonpopup("Error", result.status_msg, "normal");
                this.booking_payload.date = prevdate;
              }
            }, (error: any) => {
            });
        } else {
          // this.loginpop.opencommonpopup("Payment Method", "Please select a payment method", "normal");
          this.bookingPayModeErr = true;
          // console.log("test5");
        }
      } else {
        // console.log("test4");
        if (!(this.booking_payload.paymode != "" || (this.every_booking_service_charge_price == 0 && this.every_person_price == 0 && this.every_booking_price == 0))) {
          this.bookingPayModeErr = true;
        }
        $('html, body').animate({
          scrollTop: (<any>$("#bookingrequestform")).offset().top
        }, 2000);
      }
    }
  }
  getAllReview() {
    let postbody = { business: this.businessId, req_page: this.req_page, overallreq_data: this.overallreq_data };
    this.restProvider
      .postdataapi(postbody, "business/review")
      .subscribe(async (result: any) => {
        if (result.responsecode == 200) {
          // this.user_review = result.user_review;
          this.reviewMinLoader = false;
          this.more_review_status = result.more_review_status;
          this.user_review = this.user_review.concat(result.user_review);
          if (this.overallreq_data) {
            this.bs_name = result.bs_name;
            this.overall_rating = result.overall_rating;
            this.overall_rating_text = result.overall_rating_text;
            this.avg_noise_rating = result.avg_noise_rating;
            this.avg_service_rating = result.avg_service_rating;
            this.avg_food_rating = result.avg_food_rating;
            this.avg_atmosphere_rating = result.avg_atmosphere_rating;
            this.avg_waittime_rating = result.avg_waittime_rating;
            this.avg_valueformoney_rating = result.avg_valueformoney_rating;
            this.total_review_no = result.total_review_no;
            this.overallreq_data = false;
          }
          this.reviewEmptyMsg = "";
          // this.showd_overallminloader = true;
        } else if (result.responsecode == 204) {
          this.reviewMinLoader = false;
          this.reviewEmptyMsg = result.err_msg;
        } else {
          this.reviewMinLoader = false;
          this.reviewEmptyMsg = 'Some error';
        }
        // this.showd_minloader = false;
      }, (error: any) => {
        this.reviewMinLoader = false;
      });
  }
  getInfo() {
    let postbody = { business: this.businessId };
    this.restProvider
      .postdataapi(postbody, "business/info")
      .subscribe(async (result: any) => {
        if (result.responsecode == 200) {
          // this.user_review = result.user_review;
          // console.log(result)
          this.info_data.bs_name = result.bs_name;
          this.info_data.bs_delivery_sectors = result.bs_delivery_sectors;
          this.info_data.bs_details = result.bs_details;
          this.info_data.bs_opening_schedule = result.bs_opening_schedule;
          this.info_data.bs_special_openclose_schedule = result.bs_special_openclose_schedule;
        }
        // this.showd_minloader = false;
      }, (error: any) => {
      });
  }
  async set_unset_initvalue() {
    let localFetchBusinessId = await this.storage.getLocalstorage("business_id");
    let business_timestamp = await this.storage.getLocalstorage("business_timestamp");
    let clearcart = false;
    if (business_timestamp !== this.buisnessdata.info.today_token) {
      clearcart = true;
    }
    if ((localFetchBusinessId != "") || clearcart) {
      // console.log("Business Id == " + this.businessId);
      if (localFetchBusinessId != this.businessId || clearcart) {
        this.showFreeDishPopup = true;
        this.appliedfreedish = [];
        this.showFreeDishPopup = true;
        this.cartitems = [];
        this.tempcart = [];
        this.deliverytype = "";
        // this.localSt.clear('cartlist');
        this.storage.setLocalstorage("isDelMode", "");
        this.storage.setLocalstorage("minorderamnt", 0.0);
        this.storage.setLocalstorage("zone_delivery_charg", 0.0);
        this.storage.setLocalstorage("delivery_charge_waiver", 0.0);
        this.storage.setLocalstorage("cartlist", this.cartitems);
        this.storage.setLocalstorage("freedish", this.appliedfreedish);
        this.storage.setLocalstorage("reorder_order_id", 0);
        this.storage.setLocalstorage("business_id", this.businessId);
        this.storage.setLocalstorage("business_timestamp", this.buisnessdata.info.today_token);
        this.updatelistall(); // work latar

      } else {
        this.storage.setLocalstorage("business_id", this.businessId);
        if (this.deliverytype != undefined && this.deliverytype != null && this.deliverytype != '') {
          this.isDeliverySelected = true;
        }
      }
    } else {
      this.storage.setLocalstorage("business_id", this.businessId);
    }

    // clear adresss as new page load
    this.storage.removeLocalstorage("selected_addr_id");
    this.storage.removeLocalstorage("deliv_address");

  }
  updatelist(listobject: any) {
    // console.log("obj-->");
    // console.log(objet);
    listobject.product_selected_Quantity = Number(listobject.product_selected_Quantity) + 1;
    // console.log(this.category);
    if (this.search.searchmode) {
      this.searchboxon();
    }
  }
  updatelistall() {
    let tempidarray = [];
    if (this.cartitems.length > 0) {
      for (let titem of this.cartitems) {
        let tempitem = new CartModel(titem);
        let breakflag = false;
        if (tempitem.get_main_pro_id() == '' || tempitem.get_main_pro_id() == null) {
          //main product
          let mainid = tempitem.get_pro_id();
          let menucat: any
          for (menucat of this.category) {
            for (let itrproduct of menucat.product) {
              if (mainid == itrproduct.product_id) {
                if (tempidarray.indexOf(mainid) > 0) {
                  itrproduct.product_selected_Quantity = Number(itrproduct.product_selected_Quantity) + Number(tempitem.get_product_qnty());
                  //tempidarray.push(mainid);
                } else {
                  itrproduct.product_selected_Quantity = Number(tempitem.get_product_qnty());
                  tempidarray.push(mainid);
                }
                breakflag = true;
              }
              if (breakflag) {
                break;
              }
            }
            if (breakflag) {
              break;
            }
          }
        } else {
          //subproduct
          let subid = tempitem.get_pro_id();
          let mainid = tempitem.get_main_pro_id();
          let menucat: any
          for (menucat of this.category) {
            for (let itrproduct of menucat.product) {
              if (mainid == itrproduct.product_id) {
                for (let subitrproduct of itrproduct.sub_product) {
                  if (subid == subitrproduct.product_id) {
                    if (tempidarray.indexOf(subid) > 0) {
                      subitrproduct.product_selected_Quantity = Number(subitrproduct.product_selected_Quantity) + Number(tempitem.get_product_qnty());
                    } else {
                      subitrproduct.product_selected_Quantity = Number(tempitem.get_product_qnty());
                      tempidarray.push(subid);
                    }
                    breakflag = true;
                    break;
                  }
                  if (breakflag) {
                    break;
                  }
                }
              }
              if (breakflag) {
                break;
              }
            }
            if (breakflag) {
              break;
            }
          }
        }
      }
    }
    if (this.search.searchmode) {
      this.searchboxon();
    }
  }
  tempCalcOptionPrice(){
    this.tempOptionPrice = 0;
    // console.log(this.selectedOption);
    for(let [key, value] of Object.entries(this.selectedOption)){
      // newoparr.push(value);
      let subop:any = value;
      if(subop!=undefined && subop.selection != undefined){
        subop.selection.map((x:any)=>{
          let t1totalprice = Number(x.itemPrice)*Number(x.itemQuantity);
          this.tempOptionPrice += t1totalprice;
        })
      }
    }
    // this.displayTotal = Number(this.displayTotal)+Number(this.tempOptionPrice);
  }
  openOptionPop(x: any = [], obj: any, is_sub: any, main: any) {
    this.optionModalErrorMsg = '';
    this.tempOptionPrice = 0;
    this.selectedOption = {}; // reset selection with every popup open
    // console.log(x)
    if (!this.delpossible && !this.pickuppossible) {
      // order can't be taken
      this.modalService.open(this.closeRest);
      return;
    }
    if (this.deliverytype != '') {
      this.isDeliverySelected = true;
    }
    if(this.isDeliverySelected){
      for(let [key, value] of Object.entries(x)){
        console.log(value);
        let t1val: any = value;
        this.tempOptionValidation[t1val.optionId]={
          optionId:t1val.optionId,
          optionIsRequired:t1val.optionIsRequired,
          optionMaxQuantity:t1val.optionMaxQuantity,
          optionMaxSelection:t1val.optionMaxSelection,
          optionMinSelection:t1val.optionMinSelection,
          isValid:'no',
          subOption:[

          ]
        }
        
        this.tempOptionValidation[t1val.optionId].subOption.push({
          optionId:t1val.optionId,
          optionIsRequired:t1val.optionIsRequired,
          optionMaxQuantity:t1val.optionMaxQuantity,
          optionMaxSelection:t1val.optionMaxSelection,
          optionMinSelection:t1val.optionMinSelection,
          isValid:'no',
          items: []
        });
      }
      console.log(this.tempOptionValidation);
      this.productOptionPayload = x;
      this.modalService.open(this.extrapopup, { size: "lg", windowClass: 'optionmodal-customclass' });

      this.optionPopData = JSON.parse(JSON.stringify(obj));
      this.displayTotal = Number(obj.product_price);
      this.option_current_data.is_sub = is_sub;
      this.option_current_data.mainobj = main;
      this.option_current_data.subobj = obj;
    }else{
      
      let business_info = this.buisnessdata.info;
      if ( business_info.business_delivery == "no" && business_info.business_pickup == "no" ) {
        // console.log("Not available");
        this.modalService.open(this.closeRest);
      } else if ( business_info.business_delivery == "yes" || business_info.business_pickup == "yes" ) {
        this.temp_prod_obj.temp_sub_product = obj;
        this.temp_prod_obj.temp_main_product = main;
        this.temp_prod_obj.temp_type_product = is_sub;
        this.temp_prod_obj.temp_type = "extra";
        this.temp_prod_obj.productOptionPayload = x;
        this.isTempProduct = true;
        this.modalService.open(this.deliverymodal);
      } else {
        if (business_info.business_delivery == "yes" || this.deliverytype == "delivery") {
          this.modalService.open(this.zipcodemodal);
        } else {
          this.deliverytype = "pickup";
          this.openOptionPop(x, obj, is_sub, main);
        }
      }
    }
  }
  optionValidation(){
    let t1opcount = 0;
    let divid = '';
    let tempdivid = '';
    let validationflag = true;
    let optionValidation:any = [];
    // console.log(this.tempOptionValidation)
    // console.log(this.selectedOption)
    for(let [key, value] of Object.entries(this.tempOptionValidation)){
      // console.log(value);
      let tval:any = value;
      for(let [key1,value1] of Object.entries(tval.subOption)){
        // console.log(value1)
        let t1val:any = value1;
        optionValidation[t1val.optionId]=t1val;
      }
    }
    
    for(let [key, value] of Object.entries(this.selectedOption)){
      // console.log(value);
      let tval:any = value;
      for(let [key1,value1] of Object.entries(this.selectedOption[key].selection)){
        // console.log(value1)
        let t1val:any = value1;
        // optionValidation[t1val.optionId].items.push(t1val);
        optionValidation[t1val.optionId].items[t1val.itemId] = t1val;
      }
    }

    console.log(optionValidation);
    console.log(this.tempOptionValidation);


    for(let [key, value] of Object.entries(optionValidation)){
      // console.log(value);
      let tval:any = value;
      let itemqnty = 0;
      for(let [key1,value1] of Object.entries(tval.items)){
        let t1val:any = value1;
        // console.log(value1);
        itemqnty += Number(t1val.itemQuantity);
        // let t1val:any = value1;
        // optionValidation[t1val.optionId]=t1val;
      }
      if(tval.optionIsRequired=='yes'){
        if(itemqnty==0){
          // no item added
          // console.log('no item added')
          validationflag = false;
        }
      }
      if(itemqnty>0){
        if(itemqnty < tval.optionMinSelection){
          // console.log(tval.optionId,itemqnty,tval.optionMinSelection,tval.optionMaxSelection,'quentity mismatch. please select minimum '+tval.optionMinSelection + 'items from '+tval.optionHeaderLabel)
          validationflag = false;
        }
        if(itemqnty > tval.optionMaxSelection){
          
          // console.log(tval.optionId,itemqnty,tval.optionMinSelection,tval.optionMaxSelection,'quentity mismatch. please select maximum '+tval.optionMinSelection + 'items from '+tval.optionHeaderLabel)
          validationflag = false;
        }
      }
      if(!validationflag && divid==''){
        if(t1opcount==0){
          divid = tempdivid;
        }else{
          divid = key;
        }
      }
      tempdivid = key;
    }
    if(!validationflag){
      this.optionModalErrorMsg = 'Please select all required option items.';
    }else{
      this.optionModalErrorMsg = '';
    }
    t1opcount++;
    let el = document.getElementById('optionid'+divid);
    el?.scrollIntoView({ behavior: 'smooth' });
    console.log(divid,el)
    return validationflag;
  }
  optionSelect(option: any, oitem: any, event: any) {
    // console.log(option,oitem,event);
    // suboption checking
    let opGroup = new OptionModel(option);
    let op = {
      "optionId": option.optionId,
      "optionHeaderLabel": option.optionHeaderLabel,
      "itemId": oitem.itemId,
      "itemName": oitem.itemName,
      "itemQuantity": 1,
      "itemPrice": oitem.itemPrice
    }
    // console.log(option,oitem);
    console.log(oitem.subOption[0]);
    this.tempOptionValidation[option.optionId].subOption.push({
      optionId:oitem.subOption[0].optionId,
      optionIsRequired:oitem.subOption[0].optionIsRequired,
      optionMaxQuantity:oitem.subOption[0].optionMaxQuantity,
      optionMaxSelection:oitem.subOption[0].optionMaxSelection,
      optionMinSelection:oitem.subOption[0].optionMinSelection,
      isValid:'no',
      items: []
    });
    if(this.selectedOption[option.optionId]!=undefined && this.selectedOption[option.optionId]!=null && this.selectedOption[option.optionId].selection != undefined && this.selectedOption[option.optionId].selection != null){
      for(let [key, value] of Object.entries(this.selectedOption[option.optionId].selection)){
        let t1value:any = value;
        if(t1value.optionId==option.optionId){
          // remove rest of all
          const parentElement1 = document.getElementById('w'+option.optionId);
          const childElement1 = document.getElementById('w'+t1value.itemId);
          try{
            this.renderer.removeChild(parentElement1, childElement1);
          }catch(err){
          }
          break;
        }
      }
    }
    // "itemQuantity": oitem.itemQuantity,
    let subopGroup = new SubOptionModel(op);
    opGroup.selection.push(subopGroup);
    this.selectedOption[option.optionId]=opGroup;
    
    this.tempCalcOptionPrice();
    if (oitem.subOption.length > 0) {
      const subOptionWrapper = this.createDivElement('total-item-menu', 'w'+oitem.itemId);
      const divElement2 = this.createDivElement('menucard-modal sub-menu', 'optionid'+option.optionId);
      const formid = 'form' +  oitem.itemId;
      const form = this.createFormElement('menucard-modal', formid);
      const header = this.createHeader(oitem.subOption[0].optionHeaderLabel);
      const iname = 'input_'+oitem.itemId;
      for(let subop of oitem.subOption[0].optionItems){
        const iid= 'input_id'+subop.itemId;
        const radio = this.createInputDiv(subop.itemId, iname,subop.itemNameLabel,subop,oitem,option.optionId);
        this.renderer.appendChild(form, radio);
      }
      
      this.renderer.appendChild(divElement2, header);
      this.renderer.appendChild(divElement2, form);
      this.renderer.appendChild(subOptionWrapper, divElement2);
      /*this.renderer.listen(newElement, 'click', ($event) => {
          this.subOptionClick($event)
      });*/

      const parentElement = document.getElementById('w'+option.optionId);
      // console.log("hh" + parentElement);
      this.renderer.appendChild(parentElement, subOptionWrapper);
      let el = document.getElementById('w'+oitem.itemId);
      el?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  optionSelectSub(option:any,oitem: any, event: any,mainopid:any) {
    if(option.subOption[0].optionShowType=='radio'){

      this.tempOptionValidation[mainopid].subOption.push({
        optionId:oitem.subOption[0].optionId,
        optionIsRequired:oitem.subOption[0].optionIsRequired,
        optionMaxQuantity:oitem.subOption[0].optionMaxQuantity,
        optionMaxSelection:oitem.subOption[0].optionMaxSelection,
        optionMinSelection:oitem.subOption[0].optionMinSelection,
        isValid:'no',
        items: []
      });
      let op = {
        "optionId": option.subOption[0].optionId,
        "optionHeaderLabel": option.subOption[0].optionHeaderLabel,
        "itemId": oitem.itemId,
        "itemName": oitem.itemName,
        "itemQuantity": 1,
        "itemPrice": oitem.itemPrice
      }
      // this.tempOptionPrice += (Number(op.itemQuantity)*Number(op.itemPrice));
      // "itemQuantity": oitem.itemQuantity,
      let subopGroup = new SubOptionModel(op);
      // for now we are concider we have only one option group
      // let t1action = 'add';
      // console.log('======================',option.subOption[0].optionId,'=============================');
      if(this.selectedOption[mainopid]!=undefined && this.selectedOption[mainopid]!=null && this.selectedOption[mainopid].selection != undefined && this.selectedOption[mainopid].selection != null){
        let t1newarr = [];
        for(let [key, value] of Object.entries(this.selectedOption[mainopid].selection)){
          let t1value:any = value;
          if(t1value.optionId==option.subOption[0].optionId){
            // remove rest of all
              const parentElement1 = document.getElementById('w'+option.itemId);
              const childElement1 = document.getElementById('w'+t1value.itemId);
              try{
                this.renderer.removeChild(parentElement1, childElement1);
              }catch(err){
              }
            break;
          }else{
            t1newarr.push(t1value);
          }
        }
        this.selectedOption[mainopid].selection = t1newarr;
      }
      this.selectedOption[mainopid].selection.push(subopGroup);
    }else{
      let checkedid:any = document.getElementById(oitem.itemId);
      let selectid:any = document.getElementById('select_'+oitem.itemId);
      if (checkedid.checked){
        selectid.disabled = false;
      }else{
        selectid.disabled = true;
      }
      let itemQuantity = selectid?.value;
      let op = {
        "optionId": option.subOption[0].optionId,
        "optionHeaderLabel": option.subOption[0].optionHeaderLabel,
        "itemId": oitem.itemId,
        "itemName": oitem.itemName,
        "itemQuantity": itemQuantity,
        "itemPrice": oitem.itemPrice
      }
      let subopGroup = new SubOptionModel(op);
      // subopGroup.subSelectionExtras.push(oitem);
      
      let t1action = 'add';
      // console.log("==================",option,oitem,event,"=========================");
      if(this.selectedOption[mainopid]!=undefined && this.selectedOption[mainopid]!=null && this.selectedOption[mainopid].selection != undefined && this.selectedOption[mainopid].selection != null){
        let t1newarr = [];
        for(let [key, value] of Object.entries(this.selectedOption[mainopid].selection)){
          let t1value:any = value;
          if(t1value.optionId==option.subOption[0].optionId && t1value.itemId==oitem.itemId){
            // remove rest of all
            t1action = 'remove';
          }else{
            t1newarr.push(t1value);
          }
        }
        this.selectedOption[mainopid].selection = t1newarr;
      }
      // for now we are concider we have only one option group
      if(t1action=='add'){
        this.selectedOption[mainopid].selection.push(subopGroup);
      }
    }
    this.tempCalcOptionPrice();
    if (oitem.subOption.length > 0) {
      const subOptionWrapper = this.createDivElement('total-item-menu', 'w'+oitem.itemId);
      const divElement2 = this.createDivElement('menucard-modal sub-menu', 'optionid'+oitem.subOption[0].optionId);
      const formid = 'form' + oitem.itemId;
      const form = this.createFormElement('menucard-modal', formid);
      const header = this.createHeader(oitem.subOption[0].optionHeaderLabel);
      const iname = 'input_'+oitem.itemId;
      for(let subop of oitem.subOption[0].optionItems){

        const radio = this.createInputDiv(subop.itemId, iname,subop.itemNameLabel,subop,oitem,mainopid);
        this.renderer.appendChild(form, radio);
      }
      
      this.renderer.appendChild(divElement2, header);
      this.renderer.appendChild(divElement2, form);
      this.renderer.appendChild(subOptionWrapper, divElement2);
      /*this.renderer.listen(newElement, 'click', ($event) => {
          this.subOptionClick($event)

        });*/

      const parentElement = document.getElementById('w'+option.itemId);
      // console.log("hh" + parentElement);
      this.renderer.appendChild(parentElement, subOptionWrapper);
      let el = document.getElementById('w'+oitem.itemId);
      el?.scrollIntoView({ behavior: 'smooth' });
    }
  }

  updateSelectQuantity(option:any,oitem: any, event: any,mainopid:any){
    let qnty = event.target.value
    if(this.selectedOption[mainopid] != undefined && this.selectedOption[mainopid] != null){
      for(let [key, value] of Object.entries(this.selectedOption[mainopid].selection)){
        let subop:any = value;
        if(subop.itemId==oitem.itemId){
          this.selectedOption[mainopid].selection[key].itemQuantity = qnty;
        }
      }
    }
    this.tempCalcOptionPrice();
  }
  createInputDiv(id:string,name:string,lbl:string,optionObj:any,parentoption:any,mainopid:any){
    const divWrapper = this.createDivElement('row mb-2', '');
    const divLeft = this.createDivElement('form-check col-lg-9', '');
    const divRight = this.createDivElement('col-lg-3 d-flex justify-content-end', '');
    let radio = ''
    if(parentoption.subOption[0].optionShowType=="checked"){
      radio = this.createCheckboxElement(id, name,optionObj,parentoption,mainopid);
    }else{
      radio = this.createRadioElement(id, name,optionObj,parentoption,mainopid);
    }
    const lb = this.createLblElement(id, lbl);

    //right div creation start
    const spanElement = this.renderer.createElement('span');
    let price = '£ '+ optionObj.itemPrice;
    this.renderer.appendChild(spanElement,document.createTextNode(price));
    this.renderer.appendChild(divRight, spanElement);
    if(parentoption.subOption[0].optionShowType=="checked"){
      let t1sname = 'select'+name.replace('input','');
      const selectbox = this.createSelectBoxElement(id,t1sname,optionObj,parentoption,mainopid);
      this.renderer.appendChild(divRight, selectbox);
    }

    this.renderer.appendChild(divLeft, radio);
    this.renderer.appendChild(divLeft, lb);
    this.renderer.appendChild(divWrapper, divLeft);
    this.renderer.appendChild(divWrapper, divRight);

    return divWrapper

  }

  createHeader(value:string){
    const header = this.createDivElement('form-check col-lg-12 mt-2', '');
    const h5Element = this.renderer.createElement('h5');
    this.renderer.appendChild(h5Element,document.createTextNode(value));
    this.renderer.appendChild(header, h5Element);
    return header
  }

  createDivElement(classname:string,id:string){
      const divElement = this.renderer.createElement('div');
      this.renderer.setAttribute(divElement, 'class', classname);
      this.renderer.setAttribute(divElement, 'id',id);
      return divElement;
  }
  createFormElement(classname:string,id:string){

    const divElement = this.renderer.createElement('form');
    this.renderer.setAttribute(divElement, 'class', classname);
    this.renderer.setAttribute(divElement, 'id',id);
    return divElement;
  }

  createRadioElement(id:string,name:string,optionObj:any,parentoption:any,mainopid:any){

    const radioElement = this.renderer.createElement('input');
    this.renderer.setAttribute(radioElement,'type','radio');
    this.renderer.setAttribute(radioElement, 'class', 'form-check-input');
    this.renderer.setAttribute(radioElement, 'id',id);
    this.renderer.setAttribute(radioElement, 'name',name);
    this.renderer.setAttribute(radioElement, 'value',name);

    this.renderer.listen(radioElement, 'change', ($event) => {

      //this.subOptionClick($event)
      // console.log(optionObj);
      // console.log(parentoption);
      if(optionObj.subOption.length > 0){
          this.optionSelectSub(parentoption,optionObj,$event,mainopid);
      }
    
    });
    return radioElement;
  }

  createSelectBoxElement(id:string,name:string,optionObj:any,parentoption:any,mainopid:any){

    const selectElement = this.renderer.createElement('select');
    this.renderer.setAttribute(selectElement, 'class', 'form-check-input option-check-quentity-select');
    this.renderer.setAttribute(selectElement, 'id',"select_"+id);
    this.renderer.setAttribute(selectElement, 'name',name);
    this.renderer.setAttribute(selectElement, 'disabled','disabled');
    this.renderer.setAttribute(selectElement, 'value',name);
    for(let i:any = 1; i<=optionObj.itemQuantity; i++){
      const option = this.renderer.createElement('option');
      this.renderer.setAttribute(option,'value',i);
      this.renderer.appendChild(option, document.createTextNode(i));
      this.renderer.appendChild(selectElement, option);
    }
    this.renderer.listen(selectElement, 'change', ($event) => {
      this.updateSelectQuantity(parentoption,optionObj,$event,mainopid);
    });
    const spanElement = this.renderer.createElement('span');
    this.renderer.appendChild(spanElement,selectElement);
    return spanElement;
  }
  createCheckboxElement(id:string,name:string,optionObj:any,parentoption:any,mainopid:any){

    const radioElement = this.renderer.createElement('input');
    this.renderer.setAttribute(radioElement,'type','checkbox');
    this.renderer.setAttribute(radioElement, 'class', 'form-check-input');
    this.renderer.setAttribute(radioElement, 'id',id);
    this.renderer.setAttribute(radioElement, 'name',name);
    this.renderer.setAttribute(radioElement, 'value',name);

    this.renderer.listen(radioElement, 'change', ($event) => {
      this.optionSelectSub(parentoption,optionObj,$event,mainopid);
      if(optionObj.subOption.length > 0){
          // this.optionSelectSub(parentoption,optionObj,$event);
      }
    
    });
    return radioElement;
  }

  createLblElement(id:string,value:string){

    const lblElement = this.renderer.createElement('label');
    this.renderer.setAttribute(lblElement, 'class', 'form-check-label');
    this.renderer.setAttribute(lblElement, 'for',id);
    this.renderer.appendChild(lblElement,document.createTextNode(value));
    return lblElement;

  }
  subOptionClick(e:any){
    console.log(e);
  }
  loaddata() {
    this.formbody.zipcode = this.pincode;
    this.formbody.slug = this.slug;
    this.restProvider
      .postdataapi(this.formbody, "business/details")
      .subscribe(async (result: any) => {
        if (String(result.responsecode) == '221') {
          this.restProvider.apiCallLogout();
          // return;
        }
        if (result.responsecode == '201') {
          if (result.postcode_url == '') {
            // this.rts.navigate(["/not-found"]);
          } else {
            this.rts.navigateByUrl(result.postcode_url);
          }
        }
        // console.log(result);
        this.buisnessdata = result;
        this.business_logo = result.info.business_logo;
        this.business_name = result.info.business_name;
        this.business_address = result.info.business_address;
        this.business_colony = result.info.business_colony;
        this.business_city = result.info.business_city;
        // this.setseo();
        this.category = result.menucategory;
        this.getAllCategorydetails(result.info.business_id);
        this.getDiscountDetails(result.info.business_id);
        result.info.subcategory = "";
        if (result.subcatagory.length > 0) {
          for (var i = 0; i < result.subcatagory.length; i++) {
            if (this.subcategory == "") {
              this.subcategory = this.subcategory;
            } else {
              this.subcategory = this.subcategory + ", ";
            }
            this.subcategory =
              this.subcategory + result.subcatagory[i].subcategory_name;
          }
          result.info.subcategory = this.subcategory;
        }
        // isdelmode == pickup then update current min order amount
        let isdelmode = await this.storage.getLocalstorage('isdelmode');
        this.businessId = result.info.business_id;
        if (result.info != undefined && result.info != null && result.info.pickup_minorder_price != undefined && result.info.pickup_minorder_price != null && result.info.pickup_minorder_price != 0) {
          if (isdelmode != undefined && isdelmode != null && isdelmode == "pickup") {
            this.storage.setLocalstorage("minorderamnt", result.info.pickup_minorder_price);
            this.minorderamnt = result.info.pickup_minorder_price;
          }
        }

        if (result.info != undefined && result.info != null && result.info.payment_permission != undefined && result.info.payment_permission != null && result.info.payment_permission[0] != undefined && result.info.payment_permission[0] != null && result.info.payment_permission[0].booking != undefined && result.info.payment_permission[0].booking != null) {
          this.booking_payment_permission = result.info.payment_permission[0].booking;
          if (result.info.payment_permission[0].booking.length == 1) {
            this.changeBookingPaytype(this.booking_payment_permission[0]["value"]);
            this.booking_payload.paymode = this.booking_payment_permission[0]["value"];
          }
        }
        this.set_unset_initvalue();

        this.booking = result.booking;
        if (this.booking.status == "yes") {
          this.max_person = Number(this.booking.max_person);
          this.min_person = Number(this.booking.min_person);
          this.every_person_price = Number(this.booking.price_per);
          this.booking_payment_accept_over_guest_number = Number(this.booking.guest_number_over_pay);
          this.bookingdescription = this.booking.description;
        }

        this.pickuppossible = result.info.pickup_order_possible != 'no' ? true : false;
        this.delpossible = result.info.delivery_order_possible != 'no' ? true : false;
        if (result.info.pickup_order_possible == 'no') {
          this.pickupmsg = 'Unavailable';
          if(this.deliverytype == "pickup"){
            this.deliverytype = '';
            this.storage.setLocalstorage('isdelmode','');
            this.storage.setLocalstorage("minorderamnt", 0);
          }
        } else if (result.info.pickup_order_possible == 'pre') {
          this.pickupmsg = 'Preorder Only'
        } else {
          this.pickupmsg = result.info.avg_pickup_delivery_time.avg_pickup_time + " minutes";
        }
        if (result.info.delivery_order_possible == 'no') {
          this.deliverymsg = 'Unavailable';
          if(this.deliverytype == "delivery"){
            this.deliverytype = '';
            this.storage.setLocalstorage('isdelmode','');
            this.storage.setLocalstorage("minorderamnt", 0);
          }
        } else if (result.info.delivery_order_possible == 'pre') {
          this.deliverymsg = 'Preorder Only'
        } else {
          this.deliverymsg = result.info.avg_pickup_delivery_time.avg_delivery_time + " minutes";
        }
        this.storage.setLocalstorage("delivery_sector", result.bs_delivery_sectors);
        this.storage.setLocalstorage("business_details", result.info);
        this.storage.setLocalstorage("business_details_heading", result.info);
      }, (error: any) => {
      });
  }

  loadaccordian() {
    $(document).ready(function () {
      (<any>$("#sidebar")).stickySidebar({
        sidebarTopMargin: 0,
        footerThreshold: 100
      });
      (<any>$("#respMenu")).aceResponsiveMenu({
        resizeWidth: "300", // Set the same in Media query
        animationSpeed: "fast", // slow, medium, fast
        accoridonExpAll: true // Expands all the accordion menu on click
      });
      if ((<any>$(window).width()) > 992) {
        // $("#respMenu > li").addClass("active");
        // $("#respMenu > li > ul").show();
        $(".accordion-contianer").addClass("active");
        $(".accordion-contianer-accordion-btn").addClass("active");
        $(".accordion-contianer-panel").show();
        $(".menu-pro-cont-div").show();
      } else {
        $(".accordion-contianer").removeClass("active");
        $(".accordion-contianer-accordion-btn").removeClass("active");
        $(".accordion-contianer-panel").hide();
        $(".menu-pro-cont-div").hide();
      }
    });
    $(function () {
      (<any>$(".wrapmenu")).adaptiveMenu();
    });
    // this.setcarasoul();
  }
  getAllCategorydetails(businessid: any) {
    let postbody = { business: businessid };
    this.menuLoader = true;
    this.restProvider
      .postdataapi(postbody, "business/menu")
      .subscribe(async (result: any) => {

        // console.log(result);
        if (result.responsecode == 200) {
          this.category = result.menu;
          // this.discount = result.discount;
          this.dishes = result.dishes;
          if (result.dishes != undefined && result.dishes.group != undefined) {
            result.dishes.group.forEach((val: any) => {
              if (val.welcome_text != undefined && val.welcome_text != '') {
                let t1 = val.welcome_text.slice(0, 25);
                this.freedishWelcomeTextArr.push({"welcome_text":String(val.welcome_text),"welcome_text_short":t1});
              }
            });
          }
          if (result.dishes != undefined && result.dishes.amount != undefined) {
            result.dishes.amount.forEach((val: any) => {
              if (val.welcome_text != undefined && val.welcome_text != '') {
                let t1 = val.welcome_text.slice(0, 25);
                this.freedishWelcomeTextArr.push({"welcome_text":String(val.welcome_text),"welcome_text_short":t1});
              }
            });
          }
          // this.default_discount = result.discount;
          this.discount_delivery_charge_waiver = result.discount_delivery_charge_waiver;
          if(result.discount_delivery_charge_waiver!=undefined && result.discount_delivery_charge_waiver!=null && result.discount_delivery_charge_waiver.discounttext && result.discount_delivery_charge_waiver.discounttext!=null ){
            if(result.discount_delivery_charge_waiver.discounttext.length>28){
              this.discount_delivery_charge_waiver_short = result.discount_delivery_charge_waiver.discounttext.slice(0,25);
            }else{
              this.discount_delivery_charge_waiver_short = result.discount_delivery_charge_waiver.discounttext;
            }
          }
          // console.log(this.category);
          this.menuLoader = false;
          this.loadaccordian();
          this.updatelistall();
          this.total_cal();
          // setTimeout(() => {
          //   this.loadbusinessdiscountowl();
          // }, 500);
        } else {
          this.category = [];
          this.menuLoader = false;
          this.loadaccordian();
        }
        // this.changetabtobokking();
        //scroll
        if ((<any>$(window).width()) < 992) {
          $('html, body').animate({
            scrollTop: (<any>$("#banner")).offset().top
          }, 200);
        }
      }, (error: any) => {
        // console.log(error);
        // console.log("data fetching failed");
      });
  }
  getDiscountDetails(businessid: any) {
    let postbody = { business: businessid };
    this.menuLoader = true;
    this.restProvider
      .postdataapi(postbody, "business/discount")
      .subscribe(async (result: any) => {
        if (result.responsecode == 200) {
          if(result.discounts!=undefined){
            this.discount = result.discounts;
            if(result.discounts.delivery!=undefined){
              this.default_discount = [...result.discounts.delivery];
            }
            if(result.discounts.pickup!=undefined){
              this.default_discount = [...result.discounts.pickup];
            }
          }
          setTimeout(() => {
            this.loadbusinessdiscountowl();
          }, 500);
          this.calculateDiscount()
        } else {
          
        }
      }, (error: any) => {
        // console.log(error);
        // console.log("data fetching failed");
      });
  }
  calculateDiscount(){
    this.applied_discount = "";
    this.manual_coupon_text = "Apply Discount Code";
    this.coupon_type = 'default';
    this.total_discount = 0.0;
    let t1applieddiscount:any = [];
    let t1_discount_total_amt = 0;
    let t1discountarr:any = [];
    if(this.discount != undefined && this.discount != null && this.deliverytype=='delivery'){
      t1discountarr = this.discount.delivery;
    }else if(this.discount != undefined && this.discount != null && this.deliverytype=='pickup') {
      t1discountarr = this.discount.pickup;
    }
    t1discountarr.forEach((val: any) => {
      if(Number(this.totalpayble)>=Number(val.minshop) ){
        let t2discounttotal = 0;
        let t2discounttotalamt = 0;
        let t2discounted_subpro_id = val.discounted_product_id;
        this.cartitems.forEach((val: any) => {
          if(t2discounted_subpro_id.includes(val.product_id)){
            t2discounttotal = t2discounttotal + (Number(val.sell_price)*Number(val.product_qnty));
          }
        });
        if(val.payby!=undefined && val.payby!=null && val.payby=='percentage'){
          t2discounttotalamt= t2discounttotal*Number(val.commonrate)/100;
        }else if(val.payby!=undefined && val.payby!=null && val.payby=='price'){
          t2discounttotalamt = Number(val.commonrate);
        }
        if(t2discounttotalamt>t1_discount_total_amt){
          t1_discount_total_amt = t2discounttotalamt;
          t1applieddiscount = val;
        }
      }
    });
    // console.log(t1applieddiscount);
    // console.log(t1_discount_total_amt);
    this.applied_discount = t1applieddiscount;
    this.total_discount = t1_discount_total_amt;
  }
  delmodechange(e: any) {
    e.preventDefault();
    this.modalService.open(this.zipcodemodal);
  }

  pickmodechange() {
    this.deliverytype = 'pickup';
    this.minorderamnt = Number(this.buisnessdata.info.pickup_minorder_price);
    this.storage.setLocalstorage("isDelMode", this.deliverytype);
    this.storage.setLocalstorage("minorderamnt", this.minorderamnt);
    this.calculateDiscount();
    this.total_cal();
  }
  public openOneMenuCategory(catid = 0) {
    if (this.search.searchmode) {

      this.search.searchmode = false;
      this.search.serchtxt = "";
      this.loadaccordian();
      setTimeout(() => {
        this.opendivid = catid;
        if (!$("#menu_tab").hasClass("active")) {
          this.settab("menu");
        }
        if ((<any>$(window).width()) < 992) {
          $(".CatAcc").removeClass("active");
          $(".CatAcc").find("ul").removeClass("slide").prop("style", "display:none");
        }
        $("#acc_" + catid).addClass("active");
        $("#acc_" + catid).find("ul").addClass("slide").prop("style", "display:block");
        $('#panel_' + catid).show();
        this.menuCatagoryShow = false;
        // var scrollPos = $("#acc_" + catid).position().top;
        // console.log(scrollPos);

        $("html, body").animate({ scrollTop: (<any>$("#acc_" + catid)).offset().top }, 500);
      }, 500);
    } else {

      this.toggleMenuCategory();
      // console.log(catid)
      // here need to check is search open or not
      this.opendivid = catid;
      if (!$("#menu_tab").hasClass("active")) {
        this.settab("menu");
      }
      if ((<any>$(window).width()) < 992) {
        $(".CatAcc").removeClass("active");
        $('#panel_' + catid).show();
      }
      $("html, body").animate({ scrollTop: (<any>$("#acc_" + catid)).offset().top }, 500);
    }
  }

  searchboxon() {
    this.search.searchmode = true;
    let menuobj = JSON.parse(JSON.stringify(this.category));
    let srcharray = [];
    let searckeystringArray = new Array();
    try {
      if (this.search != undefined && this.search != null && this.search.serchtxt != undefined && this.search.serchtxt != null) {
        let txt = this.search.serchtxt.toLowerCase();
        // console.log(menuobj);
        // searctext split
        let schstring = txt.split(" ");
        for (var i = 0; i < schstring.length; i++) {
          if (schstring[i] !== '') {
            searckeystringArray.push(schstring[i]);
          }
        }
        for (let menu of menuobj) {
          // console.log(menu); // 1, "string", false
          for (let product of menu.product) {
            // console.log(product);
            //check main product name
            let prname = product.product_name.toLowerCase();
            let check = prname.includes(txt);
            // console.log(check);
            if (check) {
              srcharray.push(product);
            } else {
              //check in search key string array
              for (let keysring of searckeystringArray) {
                let prnamespit = product.product_name.toLowerCase();
                let checkkey = prnamespit.includes(keysring);
                if (checkkey) {
                  srcharray.push(product);
                  break;
                }
              }
            }
          }
        }
        // console.log(srcharray);
      } else {
        // send track js for tracking
      }
    } catch (e) {
    }
    this.search.searchresult = srcharray;
  }

  resetsearch() {
    this.search.searchmode = false;
    this.search.serchtxt = "";
    this.search.searchresult = [];
    this.loadaccordian();
  }
  loadbusinessdiscountowl() {
    (<any>$('#owl_carousel_discount')).owlCarousel({
      margin: 10,
      loop: true,
      autoplay: true,
      autoplayTimeout: 2000,
      nav: false,
      rewind: true,
      responsive: {
        150: {
          items: 1
        },
        300: {
          items: 1
        },
        600: {
          items: 1
        },
        1200: {
          items: 2
        }
      }
    });
  }
  // selectDeliveryType(f1: any) {
  //   // console.log(f1);
  //   if (f1) {
  //     if (this.deliverytypeoption == "delivery") {
  //       this.modalService.open(this.zipcodemodal);
  //     } else {
  //       this.deliverytype = "pickup";
  //       this.minorderamnt = Number(this.buisnessdata.info.pickup_minorder_price);
  //       this.storage.setLocalstorage("minorderamnt", this.minorderamnt);
  //       this.modalService.dismissAll("Close click");
  //       this.temptocartcall();
  //     }
  //   }
  // }
  
  selectDeliveryType() {
    // console.log(f1);
    if (this.deliverytypeoption=='delivery' || this.deliverytypeoption=='pickup') {
      if (this.deliverytypeoption == "delivery") {
        this.modalService.open(this.zipcodemodal);
      } else {
        this.deliverytype = "pickup";
        this.minorderamnt = Number(this.buisnessdata.info.pickup_minorder_price);
        this.storage.setLocalstorage("minorderamnt", this.minorderamnt);
        this.modalService.dismissAll("Close click");
        this.temptocartcall();
      }
    }
  }
  checkDelivery(f1: any) {
    this.validzipcodetext = "";
    if (f1) {
      let formbody = { zipcode: this.selectedZip, business: this.businessId };
      this.restProvider
        .postdataapi(formbody, "business/delivery_status_check")
        .subscribe(async (result: any) => {
          // .postdataapi(formbody, "business/delivery_status_check")
          // .then((result: any) => {
          // console.log(result);
          if (result.responsecode == 200) {
            this.deliverytype = "delivery";
            this.storage.setLocalstorage("isDelMode", this.deliverytype);
            this.modalService.dismissAll("Close click");
            this.pincode = this.selectedZip;
            this.minorderamnt = Number(result.delivery_minorder_price);
            this.zone_delivery_charg = Number(result.delivery_price);
            this.delivery_charge_waiver = result.delivery_charge_waiver
            this.storage.setLocalstorage("minorderamnt", this.minorderamnt);
            this.storage.setLocalstorage("zone_delivery_charg", this.zone_delivery_charg);
            this.storage.setLocalstorage("delivery_charge_waiver", this.delivery_charge_waiver);
            this.storage.setLocalstorage("delivery_postcode", this.selectedZip);
            if (this.isTempProduct) {
              this.temptocartcall();
            } else {
              this.total_cal();
            }
          } else {
            this.validzipcodetext = result.status;
          }
        }, (error: any) => {
          // console.log(error);
          // console.log("data fetching failed");
        })

    }
  }
  temptocartcall() {
    this.storage.setLocalstorage("isDelMode", this.deliverytype);
    this.isTempProduct = false;
    if (this.temp_prod_obj.temp_type == 'extra') {
      this.openOptionPop(this.temp_prod_obj.productOptionPayload, this.temp_prod_obj.temp_sub_product, this.temp_prod_obj.temp_type_product, this.temp_prod_obj.temp_main_product);
    } else {
      if (this.temp_prod_obj.temp_type_product === "m") {
        this.addtocart(this.temp_prod_obj.temp_sub_product, 'm', null);
      } else {
        this.addtocart(this.temp_prod_obj.temp_sub_product, 's', this.temp_prod_obj.temp_main_product);
      }
    }
  }
  total_cal() {
    this.totalpayble = 0.0;
    this.pickup_totalfordiscount = 0.0;
    this.delivery_totalfordiscount = 0.0;
    let product_id_list = [];
    let product_list_qnty = [];
    if (this.cartitems.length > 0) {
      // console.log(this.cartitems);
      for (let sitem of this.cartitems) {
        let pitem = new CartModel(sitem);
        pitem.setitem(sitem);
        product_id_list.push(pitem.product_id);
        product_list_qnty.push(Number(pitem.get_product_qnty()));
        this.totalpayble = Number(this.totalpayble) + Number(pitem.get_sell_price()) * Number(pitem.get_product_qnty());
        this.totalpayble = Number(this.totalpayble.toFixed(2));
        if (sitem.has_discount == "y" && sitem.has_discount_pickup == 'Yes') {
          this.pickup_totalfordiscount =
            Number(this.pickup_totalfordiscount) +
            Number(pitem.get_sell_price()) * Number(pitem.get_product_qnty());
          this.pickup_totalfordiscount = Number(this.pickup_totalfordiscount.toFixed(2));
        }
        if (sitem.has_discount == "y" && sitem.has_discount_delivery == 'Yes') {
          this.delivery_totalfordiscount =
            Number(this.delivery_totalfordiscount) +
            Number(pitem.get_sell_price()) * Number(pitem.get_product_qnty());
          this.delivery_totalfordiscount = Number(this.delivery_totalfordiscount.toFixed(2));
        }
      }
      this.calculateDiscount();
      this.addaditionalcharge(); // work latar
      if (this.dishes != undefined) {
        this.freedish(product_id_list, product_list_qnty); // work latar
      }
    }
  }

  addMinutes(time: any, minutes: any) {
    let date = new Date(new Date('01/01/2015 ' + time).getTime() + minutes * 60000);
    let tempTime = ((date.getHours().toString().length == 1) ? '0' + date.getHours() : date.getHours()) + ':' +
      ((date.getMinutes().toString().length == 1) ? '0' + date.getMinutes() : date.getMinutes()) + ':' +
      ((date.getSeconds().toString().length == 1) ? '0' + date.getSeconds() : date.getSeconds());
    return tempTime;
  }
  async gotocheckout() {
    // buisnessid is save in another tab or not
    let globalBusinessId = await this.storage.getLocalstorage("business_id");
    if (globalBusinessId != this.businessId) {
      this.customEvent.opencommonpopup("Error", "The cart has been reset as another resturent is open in other tab, Please close multiple tab inorder to proceed", "normal");
      this.rts.navigate(["/"]);
    } else {
      // reseting data to stop misuse
      this.storage.setLocalstorage("business_id", this.businessId);
      this.storage.setLocalstorage("business_details", this.buisnessdata.info);
      this.storage.setLocalstorage("isDelMode", this.deliverytype);
      // let discountob = {
      //   'details': this.applied_discount,
      //   'discounttype': this.coupon_type,
      //   'discountvalue': this.total_discount
      // }
      let discountob = {
        'details': this.applied_discount,
        'discounttype': this.coupon_type,
        'discountvalue': this.total_discount
      }
      this.storage.setLocalstorage("discount", discountob);
      this.buisnessdata.info.business_service_charge.service_charge_amount = this.servicecharge;
      this.buisnessdata.info.business_service_charge.baggage_charge_amount = this.baggagecharge;
      let srcharge = this.buisnessdata.info.business_service_charge;
      this.storage.setLocalstorage("scharge", srcharge);
      this.authtoken = this.authService.getSecureToken();
      // set timing array;
      // let b_info = this.sessSt.retrieve("business_details");
      let b_info = this.buisnessdata.info;
      let t_info = "";
      let timeslots = [];
      if (this.deliverytype == "delivery") {
        t_info = b_info.delivery_timeing;
      } else {
        t_info = b_info.pickup_timeing;
      }
      for (let timeslot of t_info) {
        let starttime = timeslot[0];
        if (this.deliverymsg == 'Preorder Only' || this.pickupmsg == 'Preorder Only') {
          let tempstartdate = new Date('1/1/1999 ' + starttime);
          tempstartdate.setHours(tempstartdate.getHours() + 1);
          // if(this.deliverytype == "delivery"){
          //   tempstartdate.setHours(tempstartdate.getHours() + 1);
          // }else{
          //   tempstartdate.setMinutes(tempstartdate.getMinutes() + 30);
          // }
          starttime = tempstartdate.getHours() + ":" + tempstartdate.getMinutes() + ":" + tempstartdate.getSeconds();
          // console.log(starttime);
          let disptime = tempstartdate.getHours() + ":" + tempstartdate.getMinutes();
          let mins = tempstartdate.getMinutes() + '';
          if (mins.length == 1) {
            disptime = disptime + "0"
          }
          // console.log(disptime);
          timeslots.push(disptime);
        } else {
          let disptime = starttime.slice(0, -3);
          timeslots.push(disptime);
        }
        let endtime = timeslot[1];
        let interval = "15";
        while ((new Date('1/1/1999 ' + endtime) > new Date('1/1/1999 ' + starttime))) {
          // statements 
          starttime = this.addMinutes(starttime, interval);
          let displaystarttime = starttime.slice(0, -3);
          timeslots.push(displaystarttime);
          let timediff = Math.abs(<any>new Date('1/1/1999 ' + endtime) - <any>new Date('1/1/1999 ' + starttime));
          let minutes = Math.floor((timediff / 1000) / 60);
          if (minutes < 15) {
            break;
          }
        }
        // console.log(starttime);
        // console.log(endtime);
        // console.log(timeslots);
        this.storage.setLocalstorage("timeslot", timeslots);
      }
      if (this.authtoken) {
        this.rts.navigate(["/checkout"]);
      } else {
        this.customEvent.setguesoption(true);
        this.logfalse = true;
        let param = "on";
        this.customEvent.setloginpop(param);
        let log = this.customEvent.openpopup();
        if (log) {
          // this.modalService.open(this.loginpopContent);
          // $(".loginbtn").trigger("click");
          // alert("Login First");
          // this.loginpop.
        }
        // console.log('login');
      }

    }
  }
  async addaditionalcharge() {
    if (this.deliverytype == 'delivery') {
      if (this.totalpayble >= this.delivery_charge_waiver && this.delivery_charge_waiver > 0) {
        this.additionalchageamnt = 0;
        this.zone_delivery_charg = 0;
      } else {
        this.zone_delivery_charg = Number(await this.storage.getLocalstorage("zone_delivery_charg"))
        this.additionalchageamnt = Number(this.zone_delivery_charg);
      }
      // this.localSt.store("zone_delivery_charg", this.zone_delivery_charg);
    } else {
      this.additionalchageamnt = 0;
    }
    //service charge calculation
    if (this.buisnessdata != undefined && this.buisnessdata.info != undefined && this.buisnessdata.info.business_service_charge != undefined && this.buisnessdata.info.business_service_charge.service_charge_type != undefined && this.buisnessdata.info.business_service_charge.service_charge_type != null && this.buisnessdata.info.business_service_charge.service_charge_type != '') {
      if (this.buisnessdata.info.business_service_charge.service_charge_type == 'fixed_price') {
        this.servicecharge = Number(this.buisnessdata.info.business_service_charge.service_charge);
      } else {
        this.servicecharge = (this.totalpayble * (Number(this.buisnessdata.info.business_service_charge.service_charge) / 100));
        this.servicecharge = Number(this.servicecharge.toFixed(2));
      }
    }
    if (this.buisnessdata != undefined && this.buisnessdata.info != undefined && this.buisnessdata.info.business_service_charge != undefined && this.buisnessdata.info.business_service_charge.baggage_charge_type != undefined && this.buisnessdata.info.business_service_charge.baggage_charge_type != null && this.buisnessdata.info.business_service_charge.baggage_charge_type != '') {
      if (this.buisnessdata.info.business_service_charge.baggage_charge_type == 'fixed_price') {
        this.baggagecharge = Number(this.buisnessdata.info.business_service_charge.baggage_charge);
      } else {
        this.baggagecharge = (this.totalpayble * (Number(this.buisnessdata.info.business_service_charge.baggage_charge) / 100));
        this.baggagecharge = Number(this.baggagecharge.toFixed(2));
      }
    }
  }

  async freedish(product_id_list1: any[], product_list_qnty1: any[]) {
    let t1appliedfreedish = await this.storage.getLocalstorage("freedish");
    if (t1appliedfreedish != undefined && t1appliedfreedish != null && t1appliedfreedish != '') {
      this.appliedfreedish = t1appliedfreedish;
    }
    let t1product_id_list: any = []
    product_id_list1.forEach(function (t1item, id) {
      if (t1product_id_list[String(t1item)] != undefined) {
        t1product_id_list[String(t1item)] = Number(t1product_id_list[String(t1item)]) + product_list_qnty1[id];
      } else {
        // not includes
        t1product_id_list[String(t1item)] = product_list_qnty1[id];
      }
    });
    let product_id_list: any = [];
    let product_list_qnty: any = [];
    t1product_id_list.forEach(function (val: any, idx: any) {
      product_id_list.push('' + idx);
      product_list_qnty.push(val);
    });
    this.isCustomizableFreedish = 'no';
    let f1deltype = 0;
    // console.log(product_id_list);
    let oldAppliedFreedish = this.appliedfreedish;
    let oldAppliedFreedisharr: any = [];
    if (oldAppliedFreedish != undefined) {
      oldAppliedFreedish.forEach(function (val: any) {
        // oldAppliedFreedisharr
        let id = val.id;
        oldAppliedFreedisharr[id] = val;
      });
    }
    this.appliedfreedish = [];
    //check group
    for (let grp of this.dishes.group) {
      if ((this.deliverytype == 'pickup' && (grp.freedish_service_type == 'pickup' || grp.freedish_service_type == 'pickupdelivery')) || (this.deliverytype == 'delivery' && (grp.freedish_service_type == 'delivery' || grp.freedish_service_type == 'pickupdelivery'))) {
        f1deltype = 1;
        let flag = true;
        let lowestquotient = 0;
        for (let eachprod of grp.condition.product_list) {
          let index = product_id_list.indexOf(eachprod.pro_id);
          if (!product_id_list.includes(eachprod.pro_id) || (index > -1 && Number(eachprod.qty) > Number(product_list_qnty[index]))) {
            flag = false;
            break;
          }
          let quotient = Math.floor(Number(product_list_qnty[index]) / Number(eachprod.qty));
          if (quotient < lowestquotient || lowestquotient == 0) {
            lowestquotient = quotient;
          }
        }
        if (flag) {
          grp.condition.qnty = lowestquotient;
          // console.log(this.appliedfreedish);
          // console.log(lowestquotient);
          if (grp.condition.independent || this.appliedfreedish.length == 0) {
            let t1grp = grp.condition;
            t1grp.welcome_text = grp.welcome_text;
            t1grp.is_selectable = grp.is_selectable;
            if (grp.is_selectable != undefined && Number(grp.is_selectable) > 0) {
              let t1newfreedish = [];
              if (oldAppliedFreedisharr[grp.id] != undefined && oldAppliedFreedisharr[grp.id] != null) {
                t1grp.freedish_item = oldAppliedFreedisharr[grp.id]['freedish_item'];
                t1grp.is_selectable = oldAppliedFreedisharr[grp.id]['is_selectable'];
                if (Number(grp.is_selectable) > 0) {
                  this.isCustomizableFreedish = 'yes';
                }
              } else {
                for (let item of grp.condition.freedish_item) {
                  if (t1newfreedish.length < Number(grp.is_selectable)) {
                    item.selected = 'yes';
                    item.disable = 'no';
                    t1newfreedish.push(item);
                  } else {
                    item.selected = 'no';
                    item.disable = 'yes';
                    t1newfreedish.push(item);
                    this.isCustomizableFreedish = 'yes';
                  }
                }
                t1grp.freedish_item = t1newfreedish
              }
              // this.open_freedish_option();
            } else {
              let t1newfreedish = [];
              for (let item of grp.condition.freedish_item) {
                item.selected = 'yes';
                item.disable = 'yes';
                t1newfreedish.push(item);
              }
              t1grp.freedish_item = t1newfreedish
            }
            this.appliedfreedish.push(t1grp);
            // console.log(this.appliedfreedish);
          }
          // break;
        }
      }
    }
    //check amount
    for (let amnt of this.dishes.amount) {
      if ((this.deliverytype == 'pickup' && (amnt.freedish_service_type == 'pickup' || amnt.freedish_service_type == 'pickupdelivery')) || (this.deliverytype == 'delivery' && (amnt.freedish_service_type == 'delivery' || amnt.freedish_service_type == 'pickupdelivery'))) {
        f1deltype = 1;
        if (this.totalpayble >= Number(amnt.condition.amount)) {
          amnt.condition.qnty = 1;
          if (amnt.condition.independent || this.appliedfreedish.length == 0) {
            let t1amnt = amnt.condition;
            t1amnt.welcome_text = amnt.welcome_text;
            t1amnt.is_selectable = amnt.is_selectable;
            if (amnt.is_selectable != undefined && Number(amnt.is_selectable) > 0) {
              let t1newfreedish = [];
              if (oldAppliedFreedisharr[amnt.id] != undefined && oldAppliedFreedisharr[amnt.id] != null) {
                t1amnt.freedish_item = oldAppliedFreedisharr[amnt.id]['freedish_item'];
                t1amnt.is_selectable = oldAppliedFreedisharr[amnt.id]['is_selectable'];
                if (Number(amnt.is_selectable) > 0) {
                  this.isCustomizableFreedish = 'yes';
                }
              } else {
                for (let item of amnt.condition.freedish_item) {
                  if (t1newfreedish.length < Number(amnt.is_selectable)) {
                    item.selected = 'yes';
                    item.disable = 'no';
                    t1newfreedish.push(item);
                  } else {
                    item.selected = 'no';
                    item.disable = 'yes';
                    t1newfreedish.push(item);
                    this.isCustomizableFreedish = 'yes';
                  }
                }
                t1amnt.freedish_item = t1newfreedish
              }
              t1amnt.is_selectable = amnt.is_selectable;
            } else {
              let t1newfreedish = [];
              for (let item of amnt.condition.freedish_item) {
                item.selected = 'yes';
                item.disable = 'yes';
                t1newfreedish.push(item);
              }
              t1amnt.freedish_item = t1newfreedish
            }
            this.appliedfreedish.push(t1amnt);
            // console.log(this.appliedfreedish);
          }
          break;
        }
      }
    }
    if (f1deltype == 1) {
      console.log('1.2');

      this.storage.setLocalstorage("freedish", this.appliedfreedish);
      this.calculation_freedish(oldAppliedFreedish);
    }
    // this.open_freedish_option()
    let t1FreeDishAutoPopupOpenArr: any = [];
    let t2FreeDishAutoPopupOpenArr: any = [];
    if (this.appliedfreedish.length > 0) {
      this.appliedfreedish.forEach((val: any) => {
        // this is new freedish applied id array
        t1FreeDishAutoPopupOpenArr.push(Number(val.id));
      });
      this.freeDishAutoPopupOpenArr.forEach((val: any) => {
        if (t1FreeDishAutoPopupOpenArr.includes(Number(val))) {
          t2FreeDishAutoPopupOpenArr.push(Number(val));
        }
      });
      this.freeDishAutoPopupOpenArr = t2FreeDishAutoPopupOpenArr;
      this.storage.setLocalstorage("freeDishAutoPopupOpenArr", t2FreeDishAutoPopupOpenArr);
      this.appliedfreedish.forEach((val: any) => {
        if (!this.freeDishAutoPopupOpenArr.includes(Number(val.id))) {
          this.freeDishAutoPopupOpenArr.push(Number(val.id));
          this.storage.setLocalstorage("freeDishAutoPopupOpenArr", this.freeDishAutoPopupOpenArr);
          // store in local storage
          if (val.is_selectable > 0) {
            setTimeout(() => {
              this.open_freedish_option(Number(val.id));
            }, 300);
          }
        } else {
          t1FreeDishAutoPopupOpenArr.push(Number(val.id));
        }
      });
    } else {
      this.freeDishAutoPopupOpenArr = [];
      this.storage.setLocalstorage("freeDishAutoPopupOpenArr", []);
    }
  }
  updateFreedishOption(event: any, fd1: any, fditem1: any) {
    let is_selectable = 'no';
    let fdid = fd1.id;
    let fdmaxqnty = fd1.is_selectable; // length of product selected
    let itemsid = fditem1.fd_id;
    let selected_itemqnty = 0;
    let is_disable = 'no';
    if (event.target.checked) {
      // add
      is_selectable = 'yes';
    } else {
      // remove
      is_selectable = 'no';
    }
    this.appliedfreedish.forEach(function (value: any, index: any) {
      if (value.id == fdid) {
        value.freedish_item.forEach(function (value1: any, index1: any) {
          if (value1.selected == 'yes') {
            selected_itemqnty++;
          }
        });
      }
    });
    if (selected_itemqnty < fdmaxqnty) {
      if (is_selectable == 'yes' && selected_itemqnty + 1 == fdmaxqnty) {
        //
        is_disable = 'yes';
      } else if (is_selectable == 'yes' && selected_itemqnty + 1 < fdmaxqnty) {
        is_disable = 'no';
      } else {
        is_disable = 'no';
      }
    } else if (selected_itemqnty == fdmaxqnty) {
      if (is_selectable == 'yes') {
        // not update
        is_disable = 'yes';
      } else {
        is_disable = 'no';
      }
    } else {
      is_disable = 'yes';
    }
    // let new_freedish_item = [];
    this.appliedfreedish.forEach(function (value: any, index: any) {
      if (value.id == fdid) {
        value.freedish_item.forEach(function (value1: any, index1: any) {
          let t1value1 = value1;
          if (fdmaxqnty == 1) {
            t1value1.disable = 'no';
            if (value1.fd_id == itemsid) {
              // checking 
              t1value1.selected = 'yes';
            } else {
              t1value1.selected = 'no';
            }
          } else {
            if (value1.fd_id == itemsid) {
              // checking 
              if (is_selectable == 'yes') {
                // not update
                t1value1.selected = 'yes';
                t1value1.disable = 'no';
              } else {
                t1value1.disable = is_disable;
                t1value1.selected = 'no';
              }
            } else {
              if (value1.selected == 'yes') {
                t1value1.disable = 'no';
              } else {
                t1value1.disable = is_disable;
              }
            }
          }
          // new_freedish_item.push(t1value1);
        });
        // this.appliedfreedish[index].freedish_item=new_freedish_item;
      }
    });
    this.storage.setLocalstorage("freedish", this.appliedfreedish);
  }
  open_freedish_option(id = 0) {
    this.showFreeDishOptionId = id;
    // need to checking customisable freedish
    this.modalService.open(this.freedishoptionpopup, { size: "lg", windowClass: 'optionmodal-customclass' });
    return;
  }
  calculation_freedish(oldAppliedFreedish: any) {
    this.freedishSaveAmount = 0;
    for (let freedish of this.appliedfreedish) {
      let freedishitems: any;
      for (freedishitems of freedish.freedish_item) {
        this.freedishSaveAmount += (Number(freedish.qnty) * Number(freedishitems.fd_price));
      }
    }
    try {
      if ((this.appliedfreedish != undefined && this.appliedfreedish.length > 0)) {
        if (oldAppliedFreedish == undefined || (oldAppliedFreedish != undefined && oldAppliedFreedish.length == 0)) {
          if (this.showFreeDishPopup) {
            this.freeDishSurpriseFireworks();
            this.showFreeDishPopup = false;
          }
        }
        // freedish applied
      } else {
        this.showFreeDishPopup = true;
      }
    } catch (error) {
      // console.log(error);
    }
  }

  public freeDishSurpriseFireworks() {

    this.freeDishSurpriseOn = true;
    let count = 200;
    let defaults = {
      origin: { y: 0.7 }
    };

    function fire(particleRatio: any, opts: any) {
      // confetti(Object.assign({}, defaults, opts, {
      //   particleCount: Math.floor(count * particleRatio)
      // }));
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
    setTimeout(() => {
      this.freeDishPopOff();
    }, 3500);
  }
  public freeDishSurprise(): void {
    this.freeDishSurpriseOn = true;
    const myCanvas = document.createElement('canvas');
    // document.getElementById('mainFreedish').append(myCanvas);
    let t1 = document.querySelector('.customFreedishPop');

    if (t1 != undefined && t1 != null) {
      // let t2 = t1[0].contentWindow.document.getElementsByTagName("H1")[0];
      // console.log(t1.lastChild.nodeName);
      t1.append(myCanvas);
      // const myConfetti = confetti.create(myCanvas, { resize: true, spread: 70, origin: { y: 1.2 }});
      // myConfetti();
      // setTimeout(()=>{
      //   let t2 = document.querySelector('.mainFreedish');
      //   let t3 = document.querySelector('.mainFreedish');
      //   let t2_remove_element = [];
      //   t2.childNodes.forEach((element,index) => {
      //     if(element.nodeName.toLowerCase()=="canvas"){
      //       t2_remove_element.push(element);
      //     }
      //   });
      //   t2_remove_element.reverse();
      //   t2_remove_element.forEach((element,index)=>{
      //     t3.removeChild(element);
      //   });
      // },200);
    }
    setTimeout(() => {
      this.freeDishPopOff();
    }, 3500);
  }
  freeDishPopOff() {
    this.freeDishSurpriseOn = false;
    // this.freeDishSurprise();
  }
  addqty(cartobject: any) {
    // console.log(cartobject);
    cartobject.product_qnty += 1;
    this.storage.setLocalstorage("cartlist", this.cartitems);
    this.updatelistall();
    this.total_cal();
    let displaypname = cartobject.product_name + " " + cartobject.main_pro_name;
    // this.toastr.success("added to the cart", displaypname, {
    //   timeOut: 1000
    // });
  }
  remove(prodid: any, i: any) {
    // let item = this.cartitems.find(c => c.product_id === prodid);
    let item = this.cartitems[i];
    if (item) {
      // console.log(item);
      let indQty = Number(item["product_qnty"]);
      // console.log(indQty);
      if (indQty > 1) {
        let pitem = new CartModel(item);
        pitem.copyitem(item);
        pitem.delquantity();
        this.cartitems[i] = pitem;
        this.updatelistall();
      } else {
        // console.log(this.cartitems[i]);
        if (this.cartitems[i].has_discount == 'y') {
          this.discounted_item_count--;
        }
        this.cartitems.splice(i, 1);
        this.resetitemonlist(item);
      }

      this.storage.setLocalstorage("cartlist", this.cartitems);

      this.total_cal();
      let pname =
        item["main_pro_name"].toString() +
        "  " +
        item["product_name"].toString();
    }
  }
  resetitemonlist(critem: any) {
    let tempdelitem = new CartModel(critem);
    let mainid = <any>'';
    let subid = <any>'';
    let breakflag = false;
    if (tempdelitem.get_main_pro_id() == '') {
      mainid = tempdelitem.get_pro_id();
      subid = '';
    } else {
      mainid = tempdelitem.get_main_pro_id();
      subid = tempdelitem.get_pro_id();
    }
    let menucat: any;
    for (menucat of this.category) {
      let itrproduct: any;
      for (itrproduct of menucat.product) {
        if (mainid == itrproduct.product_id) {
          if (subid != '' && subid != null) {
            for (let subitrproduct of itrproduct.sub_product) {
              if (subid == subitrproduct.product_id) {
                subitrproduct.product_selected_Quantity = Number(subitrproduct.product_selected_Quantity) - 1;
                breakflag = true;
                break;
              }
            }
          } else {
            itrproduct.product_selected_Quantity = Number(itrproduct.product_selected_Quantity) - 1;
            breakflag = true;
            break;
          }
        }
        if (breakflag) {
          break;
        }
      }
      if (breakflag) {
        break;
      }
    }
    if (this.search.searchmode) {
      this.searchboxon();
    }
  }

  addtocart(obj: any, is_sub = "m", main: any) {
    if (!this.delpossible && !this.pickuppossible) {
      // order can't be taken
      this.modalService.open(this.closeRest);
      return;
    }
    if (this.deliverytype != undefined && this.deliverytype != null && this.deliverytype != '') {
      this.isDeliverySelected = true;
    }
    if (this.isDeliverySelected == true) {
      if (is_sub == "s") {
        obj.select_product_type = "sub-product";
        obj.main_pro_id = main.product_id;
        obj.main_pro_name = main.product_name;
      } else {
        obj.select_product_type = "product";
        obj.main_pro_id = "";
        obj.main_pro_name = "";
      }
      obj.extras = obj.product_option.length > 0 ? obj.extras : "";
      if (!obj.sell_price) {
        obj.sell_price = obj.product_price;
      }
      if (this.cartitems.length > 0) {
        let checkCart = this.cartitems.find(
          c => c.product_id === obj.product_id
        );
        if (!checkCart) {
          // discount check
          let pitem = new CartModel(obj);
          if ((is_sub == 's' && main.has_discount == 'Yes') || (is_sub == 'm' && obj.has_discount == 'Yes')) {
            this.discounted_item_count++;
            // console.log(this.discounted_item_count);
            if (is_sub == 's') {
              pitem.set_discount('y', main.has_discount_delivery, main.has_discount_pickup);
            } else {
              pitem.set_discount('y', obj.has_discount_delivery, obj.has_discount_pickup);
            }
          }
          this.cartitems.push(pitem);
          this.total_cal();
          // update list 
          this.updatelistall();
          let displaypname = obj.product_name + " " + obj.main_pro_name;

        } else {
          let getindex = this.cartitems.findIndex(
            c => c.product_id === obj.product_id
          );
          if (getindex != -1) {
            let obj1 = this.cartitems.find(
              c => obj.product_id === c.product_id
            );
            this.addqty(this.cartitems[getindex]); // work latar
          }
        }
      } else {
        this.cartitems = [];
        let pitem = new CartModel(obj);
        if ((is_sub == 's' && main.has_discount == 'Yes') || (is_sub == 'm' && obj.has_discount == 'Yes')) {
          this.discounted_item_count++;
          if (is_sub == 's') {
            pitem.set_discount('y', main.has_discount_delivery, main.has_discount_pickup);
          } else {
            pitem.set_discount('y', obj.has_discount_delivery, obj.has_discount_pickup);
          }
        }
        this.cartitems.push(pitem);
        this.updatelist(obj);
        this.total_cal();
      }
      this.storage.setLocalstorage('cartlist', this.cartitems)
    } else {
      this.temp_prod_obj.temp_sub_product = obj;
      this.temp_prod_obj.temp_main_product = main;
      this.temp_prod_obj.temp_type_product = is_sub;
      this.temp_prod_obj.temp_type = 'normal';
      this.isTempProduct = true;
      let business_info = this.buisnessdata.info;
      if (business_info.business_delivery == "no" && business_info.business_pickup == "no") {
        this.modalService.open(this.closeRest);
      } else if (business_info.business_delivery == "yes" && business_info.business_pickup == "yes") {
        this.modalService.open(this.deliverymodal);
      } else {
        if (business_info.business_delivery == "yes" || this.deliverytype == "delivery") {
          this.modalService.open(this.deliverymodal);
        } else {
          this.modalService.open(this.deliverymodal);
        }
      }
    }
    setTimeout(function () {
      $("#delivery-holder .holder").scrollTop(9999);
    }, 100);
  }

  addtocartcustomproduct(){
    let addtocartvalid = true;
    addtocartvalid = this.optionValidation();
    // check all options are valid
    if (addtocartvalid) {
      let copyobj = JSON.parse(JSON.stringify(this.optionPopData))
      if (this.option_current_data.is_sub == "s") {
        copyobj.select_product_type = "sub-product";
        copyobj.main_pro_id = this.option_current_data.mainobj.product_id;
        copyobj.main_pro_name = this.option_current_data.mainobj.product_name;
      } else {
        copyobj.select_product_type = "product";
        copyobj.main_pro_id = "";
        copyobj.main_pro_name = "";
      }
      let newoparr: any=[];
      for(let [key, value] of Object.entries(this.selectedOption)){
        newoparr.push(value);
      }
      copyobj.extras = newoparr;
      // copyobj.product_price = this.displayTotal;
      // copyobj.sell_price = copyobj.product_price;
      copyobj.sell_price = this.displayTotal+this.tempOptionPrice;
      copyobj.product_price = Number(copyobj.product_price);
      // adding to cart
      console.log(copyobj);
      let pitem = new CartModel(copyobj);
      this.cartitems.push(pitem);
      this.total_cal();
      this.storage.setLocalstorage("cartlist", this.cartitems);
      this.modalService.dismissAll("Close click");
      this.updatelistall();
    }
  }
  settab(tab: any) {
    // this.bookingSubmitBtnClick = '';
    window.scroll(0, 0);
    this.selected_tab = tab;
    let tabid = tab + "_tab";
    $(".nav-link").removeClass("active");
    $(".tab-pane").removeClass("show active");
    $("#" + tabid).addClass("active");
    $("#" + tab + '_div').addClass("show active");
    if (!$("#menu_tab").hasClass("active")) {
      this.opendivid = 0;
      $(".CatAcc").removeClass("active");
      $(".CatAcc").find("ul").removeClass("slide").prop("style", "display:none");
    }
    if (tabid == 'review_tab' && this.review_load && this.businessId != 0) {
      // this.showd_minloader = true;
      this.getAllReview();
      this.review_load = false;
    } else if (tabid == 'info_tab' && this.info_load && this.businessId != 0) {
      // this.showd_minloader = true;
      this.getInfo();
      this.info_load = false;
    }
  }
  toggleMenuCategory() {
    this.menuCatagoryShow = !this.menuCatagoryShow;
  }
  cart_toggle() {
    $("#mobileCartPopup").toggleClass("open");
    this.settab('menu');
    // $("#mobileCartPopup").fadeToggle(300);
  }
  booking_cart_toggle(){
    $("#mobileBookingCartPopup").toggleClass("open");
  }
  public openallergypopup() {
    let str = this.buisnessdata.info.allergy_info;
    str = str.replace(/(<([^>]+)>)/ig, '');
    this.customEvent.opencommonpopup("Allergy Info", str, "normal");
  }
  public bookingNoteReadMoreLess(x = 'less') {
    if (x == 'less') {
      this.special_booking_note_read = 'less';
      let l1 = this.special_booking_note.length;
      if (l1 > 350) {
        this.special_booking_note_temp = this.special_booking_note.substring(0, 353);
      } else {
        this.special_booking_note_temp = this.special_booking_note;
      }
    } else {
      this.special_booking_note_read = 'more';
      this.special_booking_note_temp = this.special_booking_note;
    }
  }

  onBookingDateSelect(e: any) {
    // console.log(e);
    // console.log(this.booking_payload.date);
    // let jvscripdt=new Date(this.booking_payload.date);
    this.bookingshowdloader = true;
    (<any>$('.owl-carousel')).owlCarousel('destroy');
    this.booking_date_label = "";
    if (this.booking_payload.date.day > 9) {
      this.booking_date_label += this.booking_payload.date.day;
    } else {
      this.booking_date_label += "0" + this.booking_payload.date.day;
    }
    if (this.booking_payload.date.month > 9) {
      this.booking_date_label += "-" + this.booking_payload.date.month;
    } else {
      this.booking_date_label += "-0" + this.booking_payload.date.month;
    }
    this.booking_date_label += "-" + this.booking_payload.date.year;
    this.booking_payload.paymode = '';
    this.booking_payload.time = '';
    this.booking_payload.guest = '';
    this.total_booking_person_price = 0;
    this.total_booking_service_charge_price = 0;
    let booktimepayload = { "business_id": this.businessId, "date": this.booking_payload.date.year + "-" + this.booking_payload.date.month + "-" + this.booking_payload.date.day }
    this.restProvider
      .postdataapi(booktimepayload, "business/booking_time")
      .subscribe(async (result: any) => {
        // console.log(result);
        this.bookingshowdloader = false;
        if (result.responsecode == "200") {
          this.timesloarray = [];
          if (result.booking_time_slot != undefined && result.booking_time_slot != null && result.booking_time_slot.slots != undefined && result.booking_time_slot.slots != null && result.booking_time_slot.slots.length > 0) {
            result.booking_time_slot.slots.map((x: any) => {
              this.timesloarray.push(x.time)
            });
            // this.timesloarray = result.booking_time_slot.slots;
          } else {
            this.createbookingslot(result.info.resturent_timeing);
          }
          if (result.booking_time_slot != undefined && result.booking_time_slot != null && result.booking_time_slot.slot_id != undefined && result.booking_time_slot.slot_id != null && Number(result.booking_time_slot.slot_id) > 0) {
            this.booking_payload.time_slot_id = Number(result.booking_time_slot.slot_id);
          }
          if (result.every_person_price != undefined) {
            this.every_person_price = Number(result.every_person_price);
          } else {
            this.every_person_price = 0;
          }
          if (result.every_booking_price != undefined) {
            // console.log(result.every_booking_price)
            this.every_booking_price = Number(result.every_booking_price);
          } else {
            this.every_booking_price = 0;
          }
          if (result.every_booking_service_charge_type != undefined && result.every_booking_service_charge_type != null && result.every_booking_service_charge_type == 'percentage') {
            this.every_booking_service_charge_type = 'percentage';
          } else {
            this.every_booking_service_charge_type = 'fixed';
          }
          if (result.every_booking_service_charge_price != undefined && result.every_booking_service_charge_price != null && Number(result.every_booking_service_charge_price) > 0) {
            this.every_booking_service_charge_price = Number(result.every_booking_service_charge_price);
          } else {
            this.every_booking_service_charge_price = 0;
          }
          if (result.max_person != undefined && result.max_person != null && Number(result.max_person) > 0) {
            this.max_person = Number(result.max_person);
          } else {
            this.max_person = 0;
          }
          if (result.min_person != undefined && result.min_person != null && Number(result.min_person) > 0) {
            this.min_person = Number(result.min_person);
          } else {
            this.min_person = 0;
          }
          if (result.reservation_notes != undefined && result.reservation_notes != null) {
            this.special_booking_note = result.reservation_notes;
            this.bookingNoteReadMoreLess();
          }
          if (result.guest_number_over_pay != undefined && result.guest_number_over_pay != null && result.guest_number_over_pay != '') {
            this.booking_payment_accept_over_guest_number = result.guest_number_over_pay;
          } else {
            this.booking_payment_accept_over_guest_number = 0;
          }
          this.calculate_booking_amount();
        } else {
          this.booking_payload.date = "";
          this.customEvent.opencommonpopup("Error", result.status, "normal");
          this.bookingshowdloader = false;
          this.timesloarray = [];
        }
      }, (error: any) => {
        // console.log(error);
        // console.log("data fetching failed");
      });
  }
  public calculate_booking_amount() {
    if (this.booking_payload.paymode == '' && this.booking_payload.date != '' && this.every_booking_service_charge_price == 0 && this.every_person_price == 0 && this.every_booking_price == 0) {
      this.booking_payload.paymode = 'cod';
    }
    if (Number(this.booking_payload.guest) > 0 && this.booking_payload.date != "") {
      let every_person_price = 0;
      if (Number(this.booking_payload.guest) > Number(this.booking_payment_accept_over_guest_number)) {
        // need payment
        every_person_price = Number(this.every_person_price);
      }
      this.total_booking_person_price = Number(this.booking_payload.guest) * every_person_price;
      // this.total_booking_price = Number(this.booking_payload.Guest) * this.every_person_price;
      this.booking_payload.total_guest_price = (Number(this.booking_payload.guest) * every_person_price) + this.every_booking_price;

      if (this.every_booking_service_charge_type == "percentage") {
        this.total_booking_service_charge_price = (this.total_booking_person_price + this.every_booking_price) * this.every_booking_service_charge_price / 100;
      } else {
        this.total_booking_service_charge_price = this.every_booking_service_charge_price;
      }
      if (this.booking_payload.total_guest_price > 0) {
        this.booking_button_label = "Book with £ " + this.booking_payload.total_guest_price;
      } else {
        this.booking_button_label = "Book";
      }
      if (Number(this.booking_payload.guest) > Number(this.booking_payment_accept_over_guest_number)) {
        if (Number(this.booking_payment_accept_over_guest_number) > 0) {
          this.booking_payload.paymode = '';
          // need to unchecked terms and conditions
          this.booking_agree_checkbox = false;
        }
        // need payment
      } else {
        // person booking charge will be zero
        if ((Number(this.booking_payload.total_guest_price)) == 0) {
          this.booking_payload.paymode = 'cod';
        }
      }
    }
  }
  public mobBookingSubmit() {
    this.bookingSubmitBtnClick = 'yes';
    console.log(this.booking_payload);
    // this.bookingMain.ngSubmit.emit();
    this.booking_call();
    if (this.booking_payload.paymode == '') {
      // this.bookingCartDataHideShow();
      this.BookingMobileCartHideShow = true;
    }
  }
  createbookingslot(t_info: any) {
    // console.log(this.buisnessdata).booking
    // let b_info = this.buisnessdata.info;
    // let t_info = "";
    let btimeslots = [];
    // t_info = b_info.resturent_timeing;
    for (let timeslot of t_info) {
      let starttime = timeslot[0];
      let disptime = starttime.slice(0, -3);
      btimeslots.push(this.toggle24hr(disptime, 0));
      let endtime = timeslot[1];
      let interval = "15";
      while ((new Date('1/1/1999 ' + endtime) > new Date('1/1/1999 ' + starttime))) {
        // statements 
        starttime = this.addMinutes(starttime, interval);
        let displaystarttime = starttime.slice(0, -3);
        btimeslots.push(this.toggle24hr(displaystarttime, 0));
        let timediff = Math.abs(<any>new Date('1/1/1999 ' + endtime) - <any>new Date('1/1/1999 ' + starttime));
        let minutes = Math.floor((timediff / 1000) / 60);
        if (minutes < 15) {
          break;
        }
      }
      this.timesloarray = btimeslots;
      btimeslots.slice(-1)
      this.bookingshowdloader = false;
    }
  }
  toggle24hr(time: any, onoff: any) {
    try {
      if (onoff == undefined) onoff = isNaN(time.replace(':', ''))// auto-detect format
      var pm = time.toString().toLowerCase().indexOf('pm') > -1 // check if 'pm' exists in the time string
      time = time.toString().toLowerCase().replace(/[ap]m/, '').split(':') // convert time to an array of numbers
      time[0] = Number(time[0])
      if (onoff) {// convert to 24 hour:
        if ((pm && time[0] != 12)) time[0] += 12
        else if (!pm && time[0] == 12) time[0] = '00'  // handle midnight
        if (String(time[0]).length == 1) time[0] = '0' + time[0] // add leading zeros if needed
      } else { // convert to 12 hour:
        pm = time[0] >= 12
        if (!time[0]) time[0] = 12 //handle midnight
        else if (pm && time[0] != 12) time[0] -= 12
      }
      return onoff ? time.join(':') : time.join(':') + (pm ? ' pm' : ' am')
    } catch (e) {
    }
  }

  show_offer_in_modal(text=""){
    console.log(text);
    if(text!=undefined && text!=null && text!=''){
      this.customEvent.opencommonpopup("Offer", text, "normal");
    }
  }
  public changeBookingPaytype(mode: any) {
    this.booking_payload.paymode = mode;
    console.log(mode);
    // this.bookingPayModeErr=false;
  }
  public menu_open_close_accrodian(menu_id = '') {
    console.log(menu_id)
    let a = $('#acc_' + menu_id);
    let b = $('#panel_' + menu_id);
    if (a.hasClass('active')) {
      a.removeClass("active");
      b.hide();
    } else {
      a.addClass("active");
      b.show();
    }
  }
  get bookingValidator() { return this.bookingForm.controls }
  setSeoNew(metaTitle = "", metaKeywords = "", metaDescription = "") {
    this.titleService.setTitle(metaTitle);
    this.metaService.addTags([
      { name: 'keywords', content: metaKeywords },
      { name: 'description', content: metaDescription },
      { name: 'robots', content: 'index, follow' }
    ]);
  }
}