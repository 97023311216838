<!-- common part for all pages start -->
<!-- header section start here -->
<app-header />

<!-- header section end here -->
<!-- common part for all pages end -->

<section class="total-listing-section mt-3">

  <div class="container">
    <div class="row">
      <!-- sidebar start here -->
      <div class="col-lg-3 filter-section">

        <ng-container *ngIf="showdloader">
          <app-loader loadername='listing-details-business-filter'></app-loader>
        </ng-container>

        <ng-container *ngIf="!showdloader">

        <div class="location-bar">
          <div class="img-location">
            <img src="../../../assets/images/banner/location.png" alt="">
            <p>{{postbody.zipcode | uppercase}}</p>
          </div>
          <div class="zoom-in-zoom-out"><a href="javascript:void(0);" (click)="changeLocation()"><img src="../../../assets/images/listing/change-location.png">Change</a></div>
          
        </div>

        <div id="accordion" class="accordion-container">
          <h4 class="accordion-title js-accordion-title">
            <div class="left-panel" (click)="toggleFilter()">
              <svg id="Layer_1" height="20" viewBox="0 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg"
                data-name="Layer 1">
                <path
                  d="m12 88.734h74.372a69.573 69.573 0 0 0 137.066 0h276.562a12 12 0 0 0 0-24h-276.562a69.573 69.573 0 0 0 -137.066 0h-74.372a12 12 0 0 0 0 24zm142.905-57.589a45.59 45.59 0 1 1 -45.59 45.589 45.641 45.641 0 0 1 45.59-45.589zm345.095 212.855h-52.819a69.572 69.572 0 0 0 -137.065 0h-298.116a12 12 0 1 0 0 24h298.116a69.573 69.573 0 0 0 137.066 0h52.818a12 12 0 1 0 0-24zm-121.352 57.589a45.59 45.59 0 1 1 45.59-45.589 45.64 45.64 0 0 1 -45.59 45.589zm121.352 121.677h-276.562a69.573 69.573 0 0 0 -137.066 0h-74.372a12 12 0 0 0 0 24h74.372a69.573 69.573 0 0 0 137.066 0h276.562a12 12 0 0 0 0-24zm-345.095 57.589a45.59 45.59 0 1 1 45.59-45.589 45.641 45.641 0 0 1 -45.59 45.589z" />
              </svg>
              Filters
            </div>
            <div class="right-panel">
              <a href="javascript:void(0);" (click)="reset_filter()">Reset</a>
            </div>
          </h4>
          <div class="accordion-content" [ngClass]="filterCollapsedFlag?'collapse':''">
            <form>
              <ng-container *ngFor="let sub of bs_subcatagory; let filter_i = index;">
                <ng-container *ngIf="sub!=undefined && sub!=null && sub.id!=undefined && sub.id!=null && sub.id!=''">
                  <a href="javascript:void(0);"  (click)="genfilter('subcatagory',sub.id)">
                    <div class="form-check" [ngClass]="sub.selected=='yes'?'active':''">
                      <div class="check-div">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                          viewBox="0 0 16 16">
                          <path
                            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                        </svg>
                        <p><img src="../../../assets/images/listing/free-delivery.png"></p>
                        <p>{{sub.subcategory_name}}</p>
                      </div>
                      <p>{{sub.quantity}}</p>
                    </div>
                  </a>
                </ng-container>
              </ng-container>
              

              <!-- <a href="javascript:void(0);">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p><img src="../../../assets/images/listing/fifty.png">50% OFF</p>
                  </div>
                  <p>10</p>
                </div>
              </a>

              <a href="javascript:void(0);">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p><img src="../../../assets/images/listing/curry.png">Indian Curry</p>
                  </div>
                  <p>25</p>
                </div>
              </a>

              <a href="javascript:void(0);">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p><img src="../../../assets/images/listing/pizza.png">Pizza</p>
                  </div>
                  <p>50</p>
                </div>
              </a>

              <a href="javascript:void(0);">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p><img src="../../../assets/images/listing/ice-cream.png">Ice Cream</p>
                  </div>
                  <p>55</p>
                </div>
              </a>

              <a href="javascript:void(0);">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p><img src="../../../assets/images/listing/desert.png">Desert</p>
                  </div>
                  <p>47</p>
                </div>
              </a>

              <a href="javascript:void(0);">
                <div class="form-check">


                  <div class="check-div">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" class="bi bi-check2"
                      viewBox="0 0 16 16">
                      <path
                        d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0" />
                    </svg>
                    <p><img src="../../../assets/images/listing/american.png">American</p>
                  </div>
                  <p>62</p>
                </div>
              </a> -->

            </form>
          </div>
        </div>
        </ng-container>
      </div>

      <!-- sidebar end here -->

      <!-- listing start here -->
      <div class="col-lg-9 search-listing">
        <ng-container *ngIf="showdloader">
          <app-loader loadername='listing-details-business-search'></app-loader>
        </ng-container>
        <ng-container *ngIf="!showdloader">
          <div class="search-bar">
            <div class="form-group">
              <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                placeholder="Search By Restaurants" [(ngModel)]="searchText">
              <app-svg-icon iconname="bs-search"></app-svg-icon>
              <a href="javascript:void(0);">Search</a>
            </div>
  
          </div>
          <div class="location-sort mt-3">
         
        
            <div class="loca-part">
              <!-- <app-svg-icon iconname="restaurant-open"></app-svg-icon> -->
              <h6><span><ng-container *ngIf="list.length > 9 else listing">{{ list.length }}</ng-container><ng-template #listing>0{{ list.length }}</ng-template> </span>Restaurents Open </h6>
  
            </div>
            <div class="sort-part">
              <a class="default-btn btn active mr-20 filter-mobile" type="button" data-bs-toggle="modal"
                data-bs-target="#exampleModal">
                <svg id="svg8" height="25" viewBox="0 0 6.3499999 6.3500002" fill="#f03157" width="25"
                  xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#"
                  xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
                  xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                  xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
                  xmlns:svg="http://www.w3.org/2000/svg">
                  <g id="layer2" transform="translate(0 -2.117)">
                    <path id="path1023"
                      d="m4.2307167 2.1166666c-.4880475 0-.8974542.3392647-1.0162096.792781h-2.94345009a.26423512.2648633 0 0 0 0 .5297266h2.94293449c.118161.4539438.5280618.792781 1.0167252.792781.4886632 0 .9001907-.3388372 1.0187875-.792781h.8306004a.26423512.2648633 0 0 0 0-.5297266h-.831116c-.1191931-.4535163-.5302245-.792781-1.0182719-.792781zm0 .5292097c.294708 0 .5279547.2343181.5279547.5297266 0 .2954087-.2332467.5292098-.5279547.5292098-.2947079 0-.5258924-.2338011-.5258924-.5292098 0-.2954085.2311845-.5297266.5258924-.5297266zm-2.1102718 1.5860789c-.4888354 0-.9003576.3407431-1.0187874.795365h-.83060049a.26400373.26463136 0 0 0 -.0252635 0 .26434585.26497429 0 0 0 .0252635.5292098h.83111609c.1191929.4535163.5302244.792781 1.0182718.792781.4880475 0 .899079-.3392647 1.0182719-.792781h2.9413878a.26404222.26466994 0 1 0 0-.5292098h-2.9408723c-.1184297-.4546219-.5299519-.795365-1.0187874-.795365zm0 .5292098c.2947081 0 .5279546.2343179.5279546.5297265s-.2332465.5292098-.5279546.5292098c-.294708 0-.5279546-.2338012-.5279546-.5292098s.2332466-.5297265.5279546-.5297265zm2.1102718 1.588146c-.4888355 0-.8987305.3407432-1.0167252.795365h-2.94293449a.26400373.26463136 0 0 0 -.0252635 0 .2643459.26497435 0 0 0 .0252635.5292097h2.94345009c.1187554.4535164.5281621.7927812 1.0162096.7927812.4880474 0 .8990788-.3392648 1.0182719-.7927812h.831116a.26404227.26466999 0 0 0 0-.5292097h-.8306004c-.1184297-.4546218-.5299522-.795365-1.0187875-.795365zm0 .5292098c.294708 0 .5279547.234318.5279547.5297265 0 .2954086-.2332467.5292098-.5279547.5292098-.2947079 0-.5258924-.2338012-.5258924-.5292098 0-.2954085.2311845-.5297265.5258924-.5297265z"
                      font-variant-ligatures="normal" font-variant-position="normal" font-variant-caps="normal"
                      font-variant-numeric="normal" font-variant-alternates="normal" font-feature-settings="normal"
                      text-indent="0" text-align="start" text-decoration-line="none" text-decoration-style="solid"
                      text-decoration-color="#000000" text-transform="none" text-orientation="mixed" white-space="normal"
                      shape-padding="0" isolation="auto" mix-blend-mode="normal" solid-color="#000000" solid-opacity="1"
                      vector-effect="none" paint-order="normal" />
                  </g>
                </svg>
              </a>
              <!-- <a href="#">
                 <h6>Sort By</h6>
                <app-svg-icon iconname="sort-by"></app-svg-icon>
               
              </a> -->
  
              <label class="dropdown">
  
                <div class="dd-button">
                  Sort By
                </div>
  
                <input type="checkbox" class="dd-input" id="test">
  
                <ul class="dd-menu">
                  <li (click)="shorting_rest_list('az')">A To Z</li>
                  <li (click)="shorting_rest_list('za')">Z To A</li>
  
  
                </ul>
  
              </label>
            </div>
         
          </div>
        </ng-container>

       

        <div class="card-system">
          
          <ng-container *ngIf="showdloader">
            <app-loader loadername='listing-details-business-list'></app-loader>
          </ng-container>
          <ng-container *ngIf="!showdloader">
            <a class="search-list" *ngFor="let item of list | cuisineSearch: searchText:cusine" href="business/{{item.business_slug}}">
              <div class="card mb-3">
                <div class="container"></div>
                <div class="row card-row">
                  <div class="col-md-4 img-div">
                    <img src="../../../assets/images/listing/image.png" class="img-fluid w-100" alt="">
                    <img src="{{ item.business_logo }}" class="rounded-start-all" alt="Logo {{item.business_name}}" >
                    <!-- <div class="love">
                      <a href="" tabindex="-1">
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-heart"
                          viewBox="0 0 16 16">
                          <path
                            d="m8 2.748-.717-.737C5.6.281 2.514.878 1.4 3.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.838-3.362.314-4.385C13.486.878 10.4.28 8.717 2.01zM8 15C-7.333 4.868 3.279-3.04 7.824 1.143q.09.083.176.171a3 3 0 0 1 .176-.17C12.72-3.042 23.333 4.867 8 15">
                          </path>
                        </svg>
                      </a>
                    </div> -->
                  </div>
                  <div class="col-md-6 body-div">
                    <div class="card-body">
                      <h5 class="card-title">{{item.business_name}}</h5>
    
                      <p class="card-text mb-2">
                        <span><img src="../../../assets/images/listing/globe.png" alt=""></span>
                        <strong>{{item.subcategory_name}}</strong>
                      </p>
                      <p class="card-text">
                        <span><img src="../../../assets/images/banner/location.png" alt=""></span>
                        {{ item.business_address }}
                      </p>
                     
                      <div class="col-deli-star">
                        <div class="star" *ngIf="item.rating!=undefined && item.rating!=null && item.rating!=''">
                          <p>{{item.rating}}
                            <!-- <app-svg-icon iconname="ratting"></app-svg-icon> -->
                             <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="#fff"
                              class="bi bi-star-fill" viewBox="0 0 16 16">
                              <path
                                d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z">
                              </path>
                            </svg> 
                          </p>
                        </div>
                        <p [class.active]="item.pickup_order_possible !='no'">Collection</p>
                        <span>|</span>
                        <p [class.active]="item.delivery_order_possible!='no'">Delivery</p>
                      </div>
                      <div class="col-deli-star content-text">
                        <p *ngIf="item.minorder_price!=''">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 112.5L0 422.3c0 18 10.1 35 27 41.3c87 32.5 174 10.3 261-11.9c79.8-20.3 159.6-40.7 239.3-18.9c23 6.3 48.7-9.5 48.7-33.4l0-309.9c0-18-10.1-35-27-41.3C462 15.9 375 38.1 288 60.3C208.2 80.6 128.4 100.9 48.7 79.1C25.6 72.8 0 88.6 0 112.5zM288 352c-44.2 0-80-43-80-96s35.8-96 80-96s80 43 80 96s-35.8 96-80 96zM64 352c35.3 0 64 28.7 64 64l-64 0 0-64zm64-208c0 35.3-28.7 64-64 64l0-64 64 0zM512 304l0 64-64 0c0-35.3 28.7-64 64-64zM448 96l64 0 0 64c-35.3 0-64-28.7-64-64z"/></svg>
                          &pound;{{ item.minorder_price }}
                        </p>
                        <p *ngIf="item.open_close_status=='closed' && (item.delivery_order_possible=='no' && item.pickup_order_possible=='no')">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> {{item.bs_next_open}}
                        </p>
                        <p *ngIf="item.open_close_status=='closed' && (item.delivery_order_possible=='pre'|| item.pickup_order_possible=='pre')">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> {{item.rest_delivery_opening_time}}
                        </p>
                        <p *ngIf="item.open_close_status=='open' && (item.delivery_order_possible=='yes'|| item.pickup_order_possible=='yes')">
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120l0 136c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2 280 120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"/></svg> 45 mins
                        </p>
                        <p *ngIf="item.promoted == 'yes' || (item.is_promoted == 'yes' && item.paid_promotion == 'yes')"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 32C28.7 32 0 60.7 0 96L0 416c0 35.3 28.7 64 64 64l448 0c35.3 0 64-28.7 64-64l0-320c0-35.3-28.7-64-64-64L64 32zM229.5 173.3l72 144c5.9 11.9 1.1 26.3-10.7 32.2s-26.3 1.1-32.2-10.7L253.2 328l-90.3 0-5.4 10.7c-5.9 11.9-20.3 16.7-32.2 10.7s-16.7-20.3-10.7-32.2l72-144c4.1-8.1 12.4-13.3 21.5-13.3s17.4 5.1 21.5 13.3zM208 237.7L186.8 280l42.3 0L208 237.7zM392 256a24 24 0 1 0 0 48 24 24 0 1 0 0-48zm24-43.9l0-28.1c0-13.3 10.7-24 24-24s24 10.7 24 24l0 96 0 48c0 13.3-10.7 24-24 24c-6.6 0-12.6-2.7-17-7c-9.4 4.5-19.9 7-31 7c-39.8 0-72-32.2-72-72s32.2-72 72-72c8.4 0 16.5 1.4 24 4.1z"/></svg> Promoted</p>
                      </div>
                      <div class="percentage-colection" *ngIf="item.default_delivery_discount != null">
                        <p *ngIf="item.default_delivery_discount.discount_text != null">
                          <span>
                            <img src="../../../assets/images/listing/percent.png" alt="">
                            {{item.default_delivery_discount.discount_text}}
                          </span>
                        </p>
                      </div>
                      <div class="percentage-colection" *ngIf="item.default_pickup_discount != null">
                        <p *ngIf="item.default_pickup_discount.discount_text != null">
                          <span>
                            <img src="../../../assets/images/listing/percent.png" alt="">
                            {{item.default_pickup_discount.discount_text}}
                          </span>
                        </p>
                      </div>
                     
                    </div>
                 
                  </div>
                  <div class="col-deli-star d-flex justify-content-end condition" *ngIf="(item.default_delivery_discount != null && item.default_delivery_discount.discount_text != null) || (item.default_pickup_discount != null && item.default_pickup_discount.discount_text != null)">
                    <p><a href="javascript:void(0);">*Conditions Apply</a></p>
                  </div>
                  <div class="col-lg-2">
                    <div class="listing-side-btn">
                    <div class="pre-order" *ngIf="(item.delivery_order_possible=='pre' && item.pickup_order_possible=='pre') || (item.delivery_order_possible=='no' && item.pickup_order_possible=='pre') || (item.delivery_order_possible=='pre' && item.pickup_order_possible=='no')">Pre-order</div>
                    <div class="order-now" *ngIf="(item.delivery_order_possible=='yes' && item.pickup_order_possible=='yes') || (item.delivery_order_possible=='pre' && item.pickup_order_possible=='yes') || (item.delivery_order_possible=='yes' && item.pickup_order_possible=='no') || (item.delivery_order_possible=='yes' && item.pickup_order_possible=='pre') || (item.delivery_order_possible=='no' && item.pickup_order_possible=='yes')">Order-now</div>
                    <div class="closed" *ngIf="item.open_close_status=='closed' && (item.delivery_order_possible=='no' && item.pickup_order_possible=='no')">Closed</div>
                  </div>
                  </div>
                
                </div>
              </div>
            </a>
              
          </ng-container>

        </div>
      </div>
      <!-- listing end here -->
    </div>

  </div>

      <!-- start change location -->
      <ng-template #change_location_modal let-modal>
        <form #changelocationform="ngForm" (ngSubmit)="changeLocationConfirm(postcode.valid)">
          <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Change Location</h4>
            <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body myform" size="lg">
            <div class="row">
              <div class="col-md-12">
                <div class="fldarea">
                  <label>Postcode<span class="text-red">*</span></label>
                  <input class="form-control" name="postcode" placeholder="Enter postcode" [(ngModel)]="newpostcode" #postcode="ngModel" required />
                  <div class="alert alert-danger"
                    *ngIf="(postcode.invalid && postcode.touched) || (postcode.invalid && changelocationform.submitted)">Postcode is required</div>
                </div>
              </div>
            </div>
            <p class="alert alert-danger" *ngIf="update_err_text!=''">{{update_err_text}}</p>
          </div>
          <div class="modal-footer justify-content-between">
            <div class="btn btn-outline-dark" (click)="modal.dismiss('Cross click')">Close</div>
            <div>
              <img src="" style="width:100%;" />
            </div>
            <div>
              <button type="submit" class="btn btn-dark btn">Update</button>
            </div>
          </div>
        </form>
      </ng-template>
      <!-- end edit_detail_modal -->


</section>


<!-- footer start here -->
<app-footer />
<!-- footer end here -->