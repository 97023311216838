import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router, NavigationExtras } from "@angular/router";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { RestService } from "../../services/rest.service";
// import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { CustomstorageService } from "../../services/customstorage.service";
declare var $: any;

@Component({
  selector: "app-listing",
  templateUrl: "./listing.component.html",
  styleUrls: ["./listing.component.scss"]
})
export class ListingComponent implements OnInit {
  @ViewChild("change_location_modal") change_location_modal: any;
  filterCollapsedFlag = false;
  postbody = {
    zipcode: "",
    catagory: "1",
    cusine_id: "0",
    sort_by: "",
    skey: ""
  };
  private api_ttl_list:any = [];
  public bs_subcatagory :any = [];
  public bs_subcatagory_selected :any = [];
  public list: any = [];
  subcatlist = [];
  showdloader = true;
  cusine = "0";
  searchText = "";
  public update_err_text = '';
  public newpostcode = '';
  constructor(
    private route: ActivatedRoute,
    public restProvider: RestService,
    private rts: Router,
    private modalService: NgbModal,
    // public localSt: LocalStorageService,
    public storage: CustomstorageService,
  ) {
    this.route.queryParams.subscribe(params => {
      // console.log(params.search_by_postcode);
      this.postbody.zipcode = params["search_by_postcode"];
      this.loaddata();
    });
  }

  print() {
    // console.log(this.cusine);
  }
  openmobilefilter() {
    // document.getElementById("filterpanel").classList.add("showfilter");
  }
  closemobilefilter() {
    // document.getElementById("filterpanel").classList.remove("showfilter");
  }

  toggleFilter(){
    if ((<any>$(window)).width() < 991) {
      this.filterCollapsedFlag = !this.filterCollapsedFlag;
    }else{
      this.filterCollapsedFlag = false;
    }
  }
  genfilter(action='subcatagory',i=''){
    this.list = [];
    let t1i = Number(i);
    if(t1i>0){
      if(this.bs_subcatagory_selected.includes(t1i)){
        let t1index_bs_subcatagory_selected = this.bs_subcatagory_selected.indexOf(t1i);
        this.bs_subcatagory_selected.splice(t1index_bs_subcatagory_selected, 1);
      }else{
        this.bs_subcatagory_selected.push(t1i);
      }
    }else{
      this.bs_subcatagory_selected=[];
    }
    console.log(this.bs_subcatagory_selected);
    this.subcatlist.map((x:any)=>{
      let t1selected = '';
      if(this.bs_subcatagory_selected.includes(Number(x.id))){
        t1selected = 'yes';
      }

      this.bs_subcatagory[Number(x.id)]={
        id: x.id,
        subcategory_name: x.subcategory_name,
        quantity: 0,
        selected: t1selected,
      };
    });
    this.api_ttl_list.map((x:any)=>{
      let is_selected = '';
      if(x.subcategory!=''){
        let subcatagory_id = x.subcategory.split(",");
        subcatagory_id.map((y:any)=>{
          let t1id = Number(y);
          if(this.bs_subcatagory_selected.includes(t1id)){
            is_selected = 'yes';
          }
          if(this.bs_subcatagory[t1id] != undefined && this.bs_subcatagory[t1id] != null){
            this.bs_subcatagory[t1id].quantity = this.bs_subcatagory[t1id].quantity + 1;
          }
        });

        
        if(this.bs_subcatagory_selected.length==0 || (this.bs_subcatagory_selected.length>0 && is_selected == 'yes')){
          this.list.push(x);
        }
      }
    });
  }
  loaddata() {
    this.update_err_text = "";
    if(this.newpostcode==''){
      this.toggleFilter();
    }
    this.restProvider
      .postdataapi(this.postbody, "search/home")
      .subscribe(async (result: any) => {
        // console.log(result);

        if (result.responsecode == "200") {
          this.newpostcode = '';
          this.storage.setLocalstorage("searched_pincode", this.postbody.zipcode);
          // this.localSt.store("searched_pincode", this.postbody.zipcode);
          this.list = result.list;
          this.api_ttl_list = result.list;
          this.subcatlist = result.subcatagory;
          // console.log(this.list);
          if(this.modalService.hasOpenModals()){
            this.modalService.dismissAll()
          }
          this.genfilter();
        } else {
          this.update_err_text = result.status;
          this.list = [];
          // this.popup.opencommonpopup("Error",result.status,"normal");
          // this.rts.navigate(["home"]);
        }

        this.showdloader = false;
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
      }, (error: any) => {
        // this.update_err_text = error.status;
        // console.log(error);
        // console.log("data fetching failed");
         this.showdloader = false;
      })
  }

  ngOnInit() { }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  changeLocation(){
    
   this.modalService.open(this.change_location_modal, { size: "md", ariaLabelledBy: "modal-basic-title" })
   .result.then(
     result => {
      //  this.closeResult = `Closed with: ${result}`;
      console.log(`Closed with: ${result}`);
     },
     reason => {
      //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      console.log(`Dismissed ${this.getDismissReason(reason)}`);
     }
   ); 
  }
  changeLocationConfirm(x:any=""){
    if(x){
      // this.postbody.zipcode = this.newpostcode;
      // this.loaddata();
      const navigationExtras: NavigationExtras = {
        queryParams: {
          search_by_postcode: this.newpostcode
        }
      };
      this.rts.navigate(["listing"], navigationExtras);
    }else{
      this.update_err_text = "Please enter valid postcode";
    }
    
  }
  shorting_rest_list(mode='az'){
    if(mode=='az'){
      // asc
      this.list.sort(function(a:any, b:any){
        let x = a.business_name.toLowerCase();
        let y = b.business_name.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }else{
      // desc
      this.list.reverse(function(a:any, b:any){
        let x = a.business_name.toLowerCase();
        let y = b.business_name.toLowerCase();
        if (x < y) {return -1;}
        if (x > y) {return 1;}
        return 0;
      });
    }
  }
  reset_filter(){
    this.genfilter('subcatagory');
    // this.bs_subcatagory_selected=[];
    // this.list=[];
    // this.list = [...this.api_ttl_list];
  }
}
