<app-header />
<section class="checkout-all">
  <div class="inner-banner">
    <div class="inner-banner"
      style="background-image: url(./assets/images/inner-banner/banner-inner.jpg);background-repeat: no-repeat;">
    </div>
    <div class="inner-banner-text">
      <h1 class="type">Check <span>Out</span></h1>
    </div>
  </div>
  <div class="container checkout">
    <ng-container *ngIf="showdloader">
      <app-loader loadername='listing-details-checkout'></app-loader>
    </ng-container>
    <div class="button-sec">
     
      <ng-container *ngIf="!showdloader">
      <div class="back">
        <a href="javascript:void(0)" type="button" (click)="goBack()">
          <span><svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" fill="#f03157" viewBox="0 0 320 512">
              <path
                d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
            </svg></span>
          <span>Back</span>
        </a>
      </div>
    </ng-container>
    </div>
    <div class="checkout-aside-1">
      <div class="row">

        <div class="col-lg-6">
          <div class="checkout-bg">
            <div class="col">
              <div class="ordername">
                <div class="service-section d-flex justify-content-between">
                  <strong>
                    <h3>Service type</h3>
                  </strong>
                  <div *ngIf="order.del_mode != 'delivery'">
                    <h3>Collection</h3>
                  </div>
                  <div *ngIf="order.del_mode == 'delivery'"><h3>Delivery</h3></div>
                </div>
              </div>
            </div>
            <div class="fldarea mb-2"><label class="cap">{{order.del_mode | titlecase}} Time</label></div>
            <select id="select-time" class="form-select" name="orderSlot" [(ngModel)]="order.slot"
              placeholder="Preferred Time">
              <option value="">Select Time</option>
              <ng-container *ngFor="let time of timearray; let in=index">
                <option value="{{time}}">{{time}}</option>
              </ng-container>
            </select>
            <div class="tab-function mt-3">
              <div class="faq-drawer">
                <input class="faq-drawer__trigger" id="faq-drawer" type="checkbox" /><label class="faq-drawer__title"
                  for="faq-drawer">
                  <div class="item-tab">
                    <div class="row mt-2">
                      <div class="col-lg-10"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
                          <path
                            d="M0 24C0 10.7 10.7 0 24 0L69.5 0c22 0 41.5 12.8 50.6 32l411 0c26.3 0 45.5 25 38.6 50.4l-41 152.3c-8.5 31.4-37 53.3-69.5 53.3l-288.5 0 5.4 28.5c2.2 11.3 12.1 19.5 23.6 19.5L488 336c13.3 0 24 10.7 24 24s-10.7 24-24 24l-288.3 0c-34.6 0-64.3-24.6-70.7-58.5L77.4 54.5c-.7-3.8-4-6.5-7.9-6.5L24 48C10.7 48 0 37.3 0 24zM128 464a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm336-48a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                        </svg> <span class="item-acor">Your order details</span> </div>
                      <!-- <div class="col-lg-2"><span>items </span></div> -->
                    </div>
                  </div>
                </label>
                <div class="faq-drawer__content-wrapper">
                  <div class="faq-drawer__content">
                    <ng-container *ngFor="let item of cartitems; let ind = index">
                      <div class="row">
                        <div class="col-lg-1 col-sm-1">
                          <div class="icon-tab"><span>{{ item.product_qnty }}x</span></div>
                        </div>
                        <div class="col-lg-11 col-sm-11">
                          <div class="tab-content1">
                            <div class="col-lg-12 d-flex justify-content-between">
                              <h4>{{ item.product_name }} {{ item.main_pro_name }}</h4>
                              <span>&pound;{{ (item.product_qnty * item.sell_price).toFixed(2) }}</span>
                            </div>
                            <ng-container *ngIf="item.extras !=''">
                              <ng-container *ngFor="let ex of item.extras">
                                <p>
                                  <span class="option-main-group">
                                    <strong>{{ex.optionHeaderLabel}}</strong>
                                  </span>
                                  <ng-container *ngFor="let selection of ex.selection">
                                    <p><span *ngIf="selection.itemQuantity>1">+{{selection.itemQuantity}} x </span><ng-container *ngIf="ex.optionId!=selection.optionId">{{selection.optionHeaderLabel}} </ng-container>{{selection.itemName}}</p>
                                  </ng-container>
                                </p>
                              </ng-container>
                            </ng-container>
                            <form>
                              <div class="input-area">
                                <textarea class="form-control form-control-sm mb-3" rows="1" name="product_comment"
                                  placeholder="Comment" [(ngModel)]="item.product_comment" maxlength="50"></textarea>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="right-panel">
                      <div class="your-order-sec">
                        <ng-container *ngIf="order.freedish!=undefined && order.freedish.length>0">
                          <div class="mainFreedish cartPage" id="mainFreedish">
                            <ng-container *ngFor="let freedish of order.freedish">
                              <ng-container *ngFor="let fd of freedish.freedish_item">
                                <div class="freeDisUnlock" *ngIf="fd.selected=='yes'">
                                  <div class="freebies-div">
                                    <div class="left">
                                      <p>{{freedish.qnty}} x <span>{{fd.fd_name}}</span> </p>
                                    </div>
                                    <div class="right">
                                      <p>&pound; {{($any(fd.fd_price | number:'1.2-2')* $any(freedish.qnty |
                                        number:'1.2-2'))| number:'1.2-2'}}</p>
                                    </div>
                                  </div>
                                </div>
                              </ng-container>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="right-panel">
                  <div class="your-order-sec">
                    <table class="subtotal">
                      <tbody>
                        <tr>
                          <td class="left"> Sub total </td>
                          <td class="right">&pound;{{ grosstotal.toFixed(2) }}</td>
                        </tr>
                        <tr *ngIf="order.del_mode == 'delivery'">
                          <td class="left">Delivery Fee</td>
                          <td class="right">&pound;{{ delivery_charg.toFixed(2) }}</td>
                        </tr>
                        <tr>
                          <td class="left"> Service charge </td>
                          <td class="right">&pound;{{ service_charge.toFixed(2) }}</td>
                        </tr>
                        <ng-container *ngIf="baggage_charg>0">
                          <tr>
                            <td class="left"> Baggage Charge </td>
                            <td class="right">&pound;{{baggage_charge | number:'1.2-2'}}</td>
                          </tr>
                        </ng-container>
                        <tr *ngIf="order.discount.discountvalue > 0">
                          <td class="left"> Discount </td>
                          <td class="right">- &pound;{{order.discount.discountvalue | number:'1.2-2'}}</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="total">
                      <tbody>
                        <tr>
                          <td class="left">Total</td>
                          <td class="right">&pound;{{ checkout_amount.toFixed(2) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="freebies-discount send-me-offer mt-3"
                    *ngIf="order.freedish!=undefined && order.freedish.length>0">
                    <table>
                      <tbody>
                        <tr>
                          <td class="left">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="20" height="20"
                              fill="#228f00">
                              <path
                                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM369 209L241 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L335 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z">
                              </path>
                            </svg>
                            <p>You Saved On Freebies</p>
                          </td>
                          <td class="right">
                            <p>&pound; {{freedishSaveAmount | number:'1.2-2'}}</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="send-me-offer ">
                    <form>
                      <div class="form-check"
                        *ngIf="user != undefined && user != null && user.sms_subscription != undefined && user.sms_subscription != null && !user.sms_subscription">
                        <input type="checkbox" class="form-check-input" name="sms_subscription" id="exampleCheck1"
                          [(ngModel)]="order.sms_subscription">
                        <label class="form-check-label">Get SMS updates on order.</label>
                      </div>
                      <div class="form-check"
                        *ngIf="user != undefined && user != null && user.bookyup_subscription != undefined && user.bookyup_subscription != null && !user.bookyup_subscription">
                        <input type="checkbox" class="form-check-input" name="bookyup_subscription" id="exampleCheck1"
                          [(ngModel)]="order.bookyup_subscription">
                        <label class="form-check-label" for="exampleCheck2">Send me offers from BookYup.</label>
                      </div>
                      <div class="form-check"
                        *ngIf="buisnessinfo != undefined && buisnessinfo.business_name != undefined">
                        <input type="checkbox" class="form-check-input" name="bs_subscription" id="exampleCheck1"
                          [(ngModel)]="order.bs_subscription">
                        <label class="form-check-label" for="exampleCheck3">Send me offers from
                          {{buisnessinfo.business_name}}.</label>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="checkout-aside-1 checkout-bg">
            <div class="row">
              <div class="col-lg-8 ">
                <h3>Your Information</h3>
              </div>
              <div class="col-lg-4" *ngIf="user != undefined && user.user_id != undefined && user.user_id !='0' && showAddrInfo">
                <div class="add-button-checkout d-flex justify-content-end">
                  <button class="btn" (click)="addnew()">Add Address</button>
                </div>
              </div>
            </div>
            <ng-container *ngIf="user != undefined && user.user_id != undefined && user.user_id !='0' && showAddrInfo">
              <div class="left-panel" *ngIf="addresslist !='null' && addresslist != ''">
                <div class="time-part-selection">
                  <div class="address-form-add mt-3">
                    <form class="address-form">
                      <div class="row">
                        <div class="owl-carousel">
                          <div class="item" *ngFor="let aditem of addresslist; let k=index">
                            <div class="form-check " [ngClass]="aditem.address_id == deladdress.address_id ? 'activeAddr' : 'inactiveAddr'" (click)="getaddress(aditem)">
                              <!-- <input type="radio" class="form-check-input" value="aditem.address_id" name="addrList-{{aditem.address_id}}" id="addressradio-{{aditem.address_id}}" [checked]="aditem.address_id == deladdress.address_id" (change)="getaddress(aditem)"> -->
                              <label class="form-check-label" for="addressradio-{{aditem.address_id}}">
                                <h5 *ngIf="aditem.is_default == 'yes'">Default Address</h5>
                                <h5 *ngIf="aditem.is_default == 'no'">Another Address</h5>
                                <span>{{ aditem.name }}</span>
                                <span>{{ aditem.address }} {{ aditem.address2 }} {{ aditem.city }}</span>

                                <span>{{ aditem.zipcode }}</span>
                                <span>{{ aditem.mobile_no }}</span>
                                <span>{{ aditem.email }}</span>
                              </label>
                              <a class="edit" href="javascript:void(0)" (click)="editAddress(aditem)">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                  <path
                                    d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z">
                                  </path>
                                </svg>
                              </a>
                            </div>
                          </div>
                          <div class="item">
                            <div class="form-check">
                              <div class="svg-sidebar">
                                <a href="javascript:void(0)" (click)="addnew()">
                                  <svg xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 448 512">
                                    <path
                                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 144L48 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l144 0 0 144c0 17.7 14.3 32 32 32s32-14.3 32-32l0-144 144 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-144 0 0-144z" />
                                  </svg>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container *ngIf="addressDetails != undefined && addressDetails != ''">
              <!-- {{addressDetails}} -->
              <div class="left-panel">
                <div class="time-part-selection">
                  <div class="address-form-add mt-3">
                    <form class="address-form">
                      <div class="row">
                        <div class="col-lg-6">
                          <div class="form-check">
                            <!-- <input type="radio" class="form-check-input" id="exampleCheck1" (change)="getaddress(user.address_book)"> -->
                            <label class="form-check-label" for="">
                              <p>{{ addressDetails.name }}</p>
                              <p>{{addressDetails.address }}</p>
                              <p>{{ addressDetails.zipcode }}</p>
                              <p>{{ addressDetails.mobile_no }}</p>
                              <p>{{ addressDetails.email }}</p>
                            </label>
                            <a class="edit" href="javascript:void(0)" (click)="editAddress(addressDetails)">
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                <path
                                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z">
                                </path>
                              </svg>
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngIf="(user != undefined && user.user_id != undefined && user.user_id =='0' && showForm) || (user != undefined && user.user_id != undefined && user.user_id >'0' && showForm) || (order.del_mode == 'delivery' && delPostFlag == true && showForm)">
              <form [formGroup]="deliveryAddresForm" (ngSubmit)="saveaddress()">
                <div class="input-area">
                  <label for=":r64:" class="sc-c89d7496-1 dUOnxx">Name *</label>
                  <input placeholder="Name" type="text" formControlName="name" autocomplete="given-name"
                    name="firstName" class="form-control">
                </div>
                <div class="alert alert-danger"
                  *ngIf="(addressDelForm['name']?.touched  && addressDelForm['name']?.errors?.['required'])">
                  Name is required
                </div>
                <div class="input-area">
                  <label for=":r64:" class="sc-c89d7496-1 dUOnxx">Email *</label>
                  <input placeholder="Email" type="email" formControlName="email" autocomplete="given-name" name="email"
                    class="form-control">
                </div>
                <div class="alert alert-danger"
                  *ngIf="(addressDelForm['email']?.touched && addressDelForm['email']?.errors?.['required'])">
                  Email is required
                </div>
                <div class="alert alert-danger"
                  *ngIf="(addressDelForm['email']?.touched && addressDelForm['email']?.errors?.['email'])">
                  Please enter valid email
                </div>
                <div class="input-area">
                  <label for=":r66:" class="sc-c89d7496-1 dUOnxx">Mobile Number. *</label>
                  <input placeholder="Phone Number" formControlName="mobile_no" name="phone" class="form-control">
                </div>
                <div class="alert alert-danger"
                  *ngIf="(addressDelForm['mobile_no']?.touched && addressDelForm['mobile_no']?.errors?.['required'])">
                  Mobile Number is required
                </div>
                <div class="alert alert-danger"
                  *ngIf="(addressDelForm['mobile_no']?.touched && addressDelForm['mobile_no']?.errors?.['pattern'])">
                  Phone number must have 11 digit and a leading 0
                </div>
                <div class="input-area" *ngIf="delivery_type=='delivery'" pattern="^(?=.*[a-zA-Z])(?=.{5,}).*$">
                  <label for=":r67:" class="sc-c89d7496-1 dUOnxx">Address *</label>
                  <input placeholder="Address" formControlName="address" type="text" name="address"
                    class="form-control">
                </div>
                <div class="input-area" *ngIf="delivery_type=='pickup'" pattern="^^(?!\s*$).+">
                  <label for=":r67:" class="sc-c89d7496-1 dUOnxx">Address *</label>
                  <input placeholder="Address" formControlName="address" type="text" name="address"
                    class="form-control">
                </div>
                <div class="alert alert-danger"
                  *ngIf="(addressDelForm['address']?.touched && addressDelForm['address']?.errors?.['required'])">
                  Address is required
                </div>
                <div class="input-area">
                  <label for=":r67:" class="sc-c89d7496-1 dUOnxx">Postcode *</label>
                  <input placeholder="Postcode" formControlName="zipcode" type="text" name="Postcode"
                    class="form-control">
                </div>
                <div class="alert alert-danger"
                  *ngIf="(addressDelForm['zipcode']?.touched && addressDelForm['zipcode']?.errors?.['required'])">
                  Postcode is required
                </div>
                <div class="error-text" *ngIf="error !=''">{{ error }}</div>
                <div class="button-sec mt-2">
                  <input type="submit" name="" value="Save & Proceed" class="btn btn-prime">
                </div>
              </form>
              <div class="button-sec mt-2" *ngIf="user.user_id > 0">
                <button class="btn order-now" [disabled]="" (click)="closeForm()">Cancel</button>
              </div>
            </ng-container>
            <ng-container *ngIf="orderBtn">
              <div class="checkout-all">
                <h3 class="innerInfo mt-2">Select Payment Method:</h3>
                <div class="checkout bottom mt-2">
                  <div class="switch-toggle left" *ngIf="buisnessinfo != undefined && buisnessinfo.payment_permission != undefined">
                    <div class="button" *ngFor="let paymodedata of buisnessinfo.payment_permission[0].order">
                      <input type="radio" id="{{paymodedata.value}}" name="dmode" value="{{paymodedata.value}}"
                        [(ngModel)]="paymode" (change)="changePaytype(paymodedata.value)" />
                      <label class="btn btn-default" for="{{paymodedata.value}}">
                        <span class="total-div mt-2">
                          <div class="left-div">
                            <app-svg-icon iconname="paypal"
                              *ngIf="paymodedata.value.toLowerCase()=='paypal'"></app-svg-icon>
                            <app-svg-icon iconname="cash" *ngIf="paymodedata.value.toLowerCase()=='cod'"></app-svg-icon>
                            <app-svg-icon iconname="card"
                              *ngIf="paymodedata.value.toLowerCase()!='paypal' && paymodedata.value.toLowerCase()!='cod'"></app-svg-icon>
                          </div>
                          <div class="right-div">
                            <h6>{{ paymodedata.leble | titlecase }}</h6>
                          </div>
                        </span>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="paymentmodeerror" class="error-txt">**Payment method is required</div>
                  <div class="mt-2">
                    <button class="btn order-now" [disabled]="!orderBtn" (click)="checkout()">Order Now</button>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ng-template #confirmation_modal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update !!!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body myform" size="lg">
    <div class="row">
      <div class="col-md-12">
        <p>We have updated the cart as some of the item value or discount has changed !!</p>
      </div>
    </div>
  </div>
  <div class="modal-footer justify-content-between">
    <div class="btn btn-outline-dark" (click)="refreshpage()">Close</div>
    <button type="button" class="btn btn-dark" (click)="refreshpage()">OK</button>
  </div>
</ng-template>
<app-footer />