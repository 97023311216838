import { Component, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomEventService } from '../../services/custom-event.service';
import { CustomstorageService } from '../../services/customstorage.service';
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { EncrdecrService } from "../../services/encryption.service";
import { RestService } from "../../services/rest.service";
import { environment } from '../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { Md5 } from "ts-md5/dist/md5";
import { Md5 } from 'ts-md5';
declare var $: any;
@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrl: './checkout.component.scss'
})
export class CheckoutComponent {
  public base_url = environment.baseUrl;
  public payment_url = environment.payment_url;
  @ViewChild("confirmation_modal") confirmation_modal: any;
  public cartitems: any = [];
  public total: Number = 0;
  public grosstotal: Number = 0;
  public delivery_charg = 0.0;
  public delivery_charge_waiver = 0.0;
  public service_charg = 0.0;
  public baggage_charg = 0.0;
  public checkout_amount = 0.0;
  public addressDetails: any;
  public delv_addr: any;
  public allergy: any;
  public buisnessinfo: any;
  public paymode = "";
  public delv_details: any;
  public user: any;
  public showForm = false;
  public editButtonClickedFlag = false;
  public service_charge = 0;
  public baggage_charge = 0;
  public orderBtn = false;
  public error: any;
  public businessid: any;
  public addressid: any;
  public order = {
    sessiontoken: <any>"",
    business_id: <any>"",
    cartitems: <any>"",
    addressid: <any>"",
    allergy_info: <any>"",
    delivery_price: <any>"",
    paymode: <any>"",
    del_mode: <any>"",
    slot: <any>"",
    user_type: "user",
    discount: <any>"",
    bookyup_subscription: false,
    sms_subscription: false,
    bs_subscription: false,
    service_charge: <any>"",
    baggage_charge: <any>"",
    address: <any>"",
    reorder_order_id: 0,
    freedish: <any>"",
  };
  public deladdress = {
    mobile_no: <any>"",
    address: <any>"",
    address2: <any>"",
    city: <any>"",
    zipcode: <any>"",
    sessiontoken: <any>"",
    businessid: <any>"",
    address_id: <any>"",
    user_type: "user",
    salt_key: <any>"",
    name: <any>"",
    email: <any>"",
    delivery_type: <any>""
  };
  delivery_type = "";
  public selected_addr_id = "";
  public showdloader = false;
  public cmntindex = -1
  public paymentmodeerror = false;
  public delPostFlag = false;
  public showAddrInfo = true;
  timearray: string[] = [];
  addresslist: any = [];
  public zipcodeseleteditem: any;
  deliverycode = 0;
  public selectedid: any;
  showbuisnesschkbox = true;
  showlimit = 4;
  redirect = true;
  public freedishSaveAmount = 0;
  deliveryAddresForm: FormGroup;
  public allergynotetxt = "Please leave a note here i.e. door bell not working and etc. In case of any allergy, it is strongly recommended to contact the restaurant before ordering";
  constructor(
    public restProvider: RestService,
    public authService: AuthService,
    public router: ActivatedRoute,
    public encodedecode: EncrdecrService,
    public CustomEventService: CustomEventService,
    private modalService: NgbModal,
    public customstorageService: CustomstorageService,
    public rts: Router,
  ) {
    this.CustomEventService.checklogincomplete().subscribe(ldata => {
      this.initpage();
    });
    this.deliveryAddresForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      mobile_no: new FormControl(null, [Validators.required, Validators.pattern('^0[0-9]{10}$')]),
      address: new FormControl(null, [Validators.required]),
      zipcode: new FormControl(null, [Validators.required])
    });
    let sessiontoken = this.authService.getSecureToken();


    if (!sessiontoken) {
      this.rts.navigate(["/"]);
    }

    if (this.cartitems == undefined || this.cartitems == null) {
      this.rts.navigate(["/"]);
    }

    //retrive free dish

    //check cart update in other tab

  }
  public get addressDelForm() {
    return this.deliveryAddresForm.controls;
  }
  ngOnInit() {
    this.initpage();
    this.getBusinessPhone();
    this.loadowl();
    this.customstorageService.observe("cartlist").subscribe(val => {
      this.CustomEventService.gohomeredirect();
    });
    this.create_timelist();
    window.scroll(0, 0);
    // this.CustomEventService.sethomeredirect(true); 
  }
  public async initpage() {
    this.addresslist = [];
    // this.showForm = true;
    let addressDetails = await this.customstorageService.getLocalstorage("addressDetails");
    // console.log(this.addressDetails);
    if (addressDetails != undefined && addressDetails != null && addressDetails != "") {
      this.addressDetails = addressDetails;
      // console.log(123);
      // this.showForm = false;
      // this.orderBtn = true;
      this.deliveryAddresForm.controls['name'].setValue(this.addressDetails.name);
      this.deliveryAddresForm.controls['email'].setValue(this.addressDetails.email);
      this.deliveryAddresForm.controls['mobile_no'].setValue(this.addressDetails.mobile_no);
      this.deliveryAddresForm.controls['address'].setValue(this.addressDetails.address);
      this.deliveryAddresForm.controls['zipcode'].setValue(this.addressDetails.zipcode);
    }
    this.businessid = await this.customstorageService.getLocalstorage("business_id");
    this.buisnessinfo = await this.customstorageService.getLocalstorage("business_details");
    let cartlist = await this.customstorageService.getLocalstorage("cartlist");
    this.order.service_charge = await this.customstorageService.getLocalstorage("scharge");

    this.order.del_mode = await this.customstorageService.getLocalstorage("isDelMode");
    if (this.order.del_mode == 'delivery') {
      this.delivery_type = "delivery";
    } else {
      this.delivery_type = "pickup";
    }
    if (this.order.service_charge != undefined && this.order.service_charge != null) {
      let scharge = this.order.service_charge;
      if (scharge != undefined && scharge != null) {
        if (scharge.service_charge_amount != undefined && scharge.service_charge_amount != null && Number(scharge.service_charge_amount) > 0) {
          this.service_charge = Number(scharge.service_charge_amount);
        }
        if (scharge.baggage_charge_amount != undefined && scharge.baggage_charge_amount != null && Number(scharge.baggage_charge_amount) > 0) {
          this.baggage_charge = Number(scharge.baggage_charge_amount);
        }
      }
    }
    this.order.discount = await this.customstorageService.getLocalstorage("discount");
    let del_price = Number(await this.customstorageService.getLocalstorage("zone_delivery_charg"));
    let del_charge_waiver = Number(await this.customstorageService.getLocalstorage("delivery_charge_waiver"));
    if (del_charge_waiver != undefined && del_charge_waiver != null) {
      if (Number(del_charge_waiver) > 0) {
        this.delivery_charge_waiver = Number(del_charge_waiver)
      }
    }
    this.service_charg = Number(this.order.service_charge.service_charge_amount);
    this.baggage_charg = Number(this.order.service_charge.baggage_charge_amount);
    if (cartlist == null) {
      this.cartitems = [];
      this.rts.navigate(["/"]);
    } else {
      this.cartitems = cartlist;
      if (this.cartitems != null) {
        this.total = 0.0;
        for (let i = 0; i < this.cartitems.length; i++) {
          let sum = (
            Number(this.cartitems[i].product_qnty) * this.cartitems[i].sell_price
          ).toFixed(2);
          this.total = Number(this.total) + Number(sum);
          this.grosstotal = this.total;
        }

        if (this.order.discount.discountvalue > 0) {

          this.order.discount.discountvalue = Number(this.order.discount.discountvalue.toFixed(1));
          this.total = Number(this.total) - this.order.discount.discountvalue;
          //console.log(this.total);
        }
        this.total = Number(this.total.toFixed(2));
        if (Number(this.total) >= this.delivery_charge_waiver && this.delivery_charge_waiver > 0) {
          this.delivery_charg = 0;
        } else {
          let del_price = Number(await this.customstorageService.getLocalstorage("zone_delivery_charg"));
          if (del_price != undefined && Number(del_price) > 0 && this.order.del_mode == 'delivery') {
            this.delivery_charg = Number(del_price);
          }
        }
        //console.log(this.total);
        this.checkout_amount = Number(this.total) + Number(this.delivery_charg) + this.service_charg + this.baggage_charg;
        this.checkout_amount = Number(this.checkout_amount.toFixed(2));
        this.order.paymode = this.paymode;
      }
    }
    this.delv_addr = await this.customstorageService.getLocalstorage("deliv_address");
    this.user = await this.customstorageService.getLocalstorage("userdata");
    if (this.user != undefined && this.user != null) {
      this.deladdress.sessiontoken = this.user.sessiontoken;

      if (this.user.address_book != null) {
        this.orderBtn = true;
        // this.order.address = this.user.address_book;
        this.addressid = this.user.address_book.address_id;
        if(this.delv_addr!=undefined && this.delv_addr!=null){
          this.order.address = this.delv_addr;
          this.deladdress.mobile_no = this.delv_addr.mobile_no;
          this.deladdress.address = this.delv_addr.address;
          this.deladdress.address2 = this.delv_addr.address2;
          this.deladdress.city = this.delv_addr.city;
          this.deladdress.zipcode = this.delv_addr.zipcode;
          this.deladdress.address_id = this.delv_addr.address_id;
          this.deladdress.name = this.delv_addr.name;
          this.deladdress.email = this.delv_addr.email;
          this.deladdress.delivery_type = this.delv_addr.delivery_type;
          this.selectedid = this.deladdress.address_id;
          console.log(this.delv_addr);
          console.log(this.deladdress);
        }else{
          this.order.address = this.user.address_book;
          this.deladdress.mobile_no = this.user.address_book.mobile_no;
          this.deladdress.address = this.user.address_book.address;
          this.deladdress.address2 = this.user.address_book.address2;
          this.deladdress.city = this.user.address_book.city;
          this.deladdress.zipcode = this.user.address_book.zipcode;
          this.deladdress.address_id = this.user.address_book.address_id;
          this.deladdress.name = this.user.address_book.name;
          this.deladdress.email = this.user.address_book.email;
          this.selectedid = this.deladdress.address_id;
        }
        this.showdloader = false;

        // set adress array
        let t1defaultAddress: any = [];
        let t1addressList: any = [];
        this.user.address_book_list.map((adressitem: any) => {
          // console.log(adressitem);
          if (adressitem.address_id != null && adressitem.address_id != undefined) {
            this.addresslist.push(adressitem);
            if (adressitem.is_default == 'yes') {
              t1defaultAddress.push(adressitem);
            } else {
              t1addressList.push(adressitem);
            }
          }
          if (adressitem.zipcode == this.deliverycode) {
            this.zipcodeseleteditem = adressitem;
          }
        });
        // this.addresslist.push(t1defaultAddress);
        this.addresslist = [...t1defaultAddress, ...t1addressList];
      }
      this.addressDetails = await this.customstorageService.getLocalstorage("addressDetails");
      console.log(this.addressDetails);
      if (this.user.user_id == 0 && this.addressDetails == null) {
        this.addressid = 0;
        this.showForm = true;
        // this.orderBtn = true;
      } else {
        this.showForm = false;
        this.orderBtn = true;
      }
    }
    this.order.sms_subscription = this.user.sms_subscription;
    // console.log(cartlist); 



    if (Number(del_price) > 0 && this.order.del_mode == 'delivery') {
      this.delivery_charg = Number(del_price);
    }
    this.order.freedish = await this.customstorageService.getLocalstorage("freedish");
    if (this.order.freedish != undefined && this.order.freedish.length > 0) {
      this.order.freedish.forEach((val: any, idx: any) => {
        if (val.freedish_item != undefined && val.freedish_item.length > 0) {
          val.freedish_item.forEach((val1: any, idx1: any) => {
            if (val1.selected != undefined && val1.selected != null) {
              if (val1.selected == 'yes') {
                this.freedishSaveAmount += (Number(val1.fd_price) * Number(val.qnty));
              }
            } else {
              this.freedishSaveAmount += (Number(val1.fd_price) * Number(val.qnty));
            }
          });
        }
      });
    }
    /* if(this.delv_addr == null || this.delv_addr == '' || this.delv_addr == undefined){
      this.rts.navigate(["/"]);
    } */

    /* let reorder_order_id = await this.customstorageService.getLocalstorage("reorder_order_id");
     if(reorder_order_id != undefined && reorder_order_id != null && Number(reorder_order_id) > 0){
       this.order.reorder_order_id = reorder_order_id;
     } */
    (<any>$('.owl-carousel')).owlCarousel('destroy')
    setTimeout(() => {
      this.loadowl();
    }, 200)
  }
  async goBack() {
    let buisnessinfo = await this.customstorageService.getLocalstorage("business_details");
    this.rts.navigate(["/business/" + buisnessinfo.business_slug]);
  }
  async getBusinessPhone() {
    let business_details_heading = await this.customstorageService.getSessionstorage("business_details_heading");
    // console.log(business_details_heading);
    if (business_details_heading != undefined && business_details_heading != null && business_details_heading.business_phone_number != undefined && business_details_heading.business_phone_number != null && business_details_heading.business_phone_number != '') {
      this.allergynotetxt += ' on ' + business_details_heading.business_phone_number + '.';
    } else {
      this.allergynotetxt += '.';
    }
  }

  comment_toggle(i: any) {

    if (this.cmntindex == i) {

      this.cmntindex = -1
    } else {

      this.cmntindex = i;
    }

    //console.log(this.cartitems);
  }

  changePaytype(mode: any) {
    this.paymode = mode;
    //console.log(mode);
    this.paymentmodeerror = false;
  }
  getaddress(adressitem: any) {
    if(!this.editButtonClickedFlag){
      this.showForm = false;
      this.orderBtn = true;
      this.showAddrInfo = true;
    }
    console.log(this.deliveryAddresForm);
    console.log(adressitem);
    this.deliveryAddresForm.controls['name'].setValue(adressitem.name);
    this.deliveryAddresForm.controls['email'].setValue(adressitem.email);
    this.deliveryAddresForm.controls['mobile_no'].setValue(adressitem.mobile_no);
    this.deliveryAddresForm.controls['address'].setValue(adressitem.address);
    this.deliveryAddresForm.controls['zipcode'].setValue(adressitem.zipcode);
    this.order.address = adressitem;
    this.addressid = adressitem.address_id;
    // console.log(this.order.address);
    this.deladdress.mobile_no = adressitem.mobile_no;
    this.deladdress.address = adressitem.address;
    this.deladdress.address2 = adressitem.address2;
    this.deladdress.city = adressitem.city;
    this.deladdress.zipcode = adressitem.zipcode;
    this.deladdress.address_id = adressitem.address_id;
    this.deladdress.name = adressitem.name;
    this.deladdress.email = adressitem.email;
    // this.error = "";
  }
  loadowl() {
    // console.log("hello");
    (<any>$('.owl-carousel')).owlCarousel({
      margin: 10,
      loop: false,
      nav: true,
      // mouseDrag: false,
      // navText: ["<<",">>"],
      navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
      rewind: true,
      responsive: {
        375: {
          items: 1
        },
        1000: {
          items: 2
        }
      }
    });
  }
  addnew() {
    this.showForm = true;
    this.orderBtn = false;
    this.showAddrInfo = false;
    // console.log(this.showForm);return;
    // this.deliveryAddresForm.controls['name'].setValue(this.deladdress.name);
    // this.deliveryAddresForm.controls['email'].setValue(this.deladdress.email);
    this.deladdress.mobile_no = "";
    this.deladdress.address = "";
    this.deladdress.address2 = "";
    this.deladdress.city = "";
    this.deladdress.zipcode = "";
    this.deladdress.address_id = "";
    this.deliveryAddresForm.controls['name'].setValue('');
    this.deliveryAddresForm.controls['email'].setValue('');
    this.deliveryAddresForm.controls['mobile_no'].setValue('');
    this.deliveryAddresForm.controls['address'].setValue('');
    this.deliveryAddresForm.controls['zipcode'].setValue('');
  }
  editAddress(adressitem: any) {
    this.editButtonClickedFlag = true;
    this.showForm = true;
    this.orderBtn = false;
    console.log(adressitem);
    /* this.deliveryAddresForm.controls['name'].setValue(this.deladdress.name);
    this.deliveryAddresForm.controls['email'].setValue(this.deladdress.email);
    this.deliveryAddresForm.controls['mobile_no'].setValue(this.deladdress.mobile_no);
    this.deliveryAddresForm.controls['address'].setValue(this.deladdress.address);
    this.deliveryAddresForm.controls['zipcode'].setValue(this.deladdress.zipcode); */
    this.deliveryAddresForm.controls['name'].setValue(adressitem.name);
    this.deliveryAddresForm.controls['email'].setValue(adressitem.email);
    this.deliveryAddresForm.controls['mobile_no'].setValue(adressitem.mobile_no);
    this.deliveryAddresForm.controls['address'].setValue(adressitem.address);
    this.deliveryAddresForm.controls['zipcode'].setValue(adressitem.zipcode);
    this.order.address = adressitem;
    this.addressid = adressitem.address_id;
    /* if(this.user.user_id == 0){
      this.deliveryAddresForm.controls['name'].setValue(adressitem.name);
      this.deliveryAddresForm.controls['email'].setValue(adressitem.email);
      this.deliveryAddresForm.controls['mobile_no'].setValue(adressitem.mobile_no);
      this.deliveryAddresForm.controls['address'].setValue(adressitem.address);
      this.deliveryAddresForm.controls['zipcode'].setValue(adressitem.zipcode);
    } */
   setTimeout(()=>{
    this.editButtonClickedFlag = false;
   },500);
  }
  closeForm() {
    this.showForm = false;
    this.orderBtn = true;
    this.showAddrInfo = true;
    this.initpage();
  }
  async create_timelist() {
    this.timearray.push("ASAP");
    this.order.slot = "ASAP";
    let temptimearray = await this.customstorageService.getLocalstorage("timeslot");
    // let today = new Date(new Date().toLocaleString('en-GB', { timeZone: 'Europe/London' }));
    let today = new Date();
    today.setHours(today.getHours() + 1);
    // if(this.order != undefined && this.order != null && this.order.del_mode != undefined && this.order.del_mode != null && this.order.del_mode == 'delivery'){
    //   today.setHours(today.getHours() + 1);
    // }else{
    //   today.setMinutes(today.getMinutes() + 30);
    // }
    //console.log("mytime"+today);
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    //this.timearray = ["11:00", "12:00", "13:00"];
    let count = 0;
    for (let timeslot of temptimearray) {
      let timesl = timeslot + ":00";
      if ((new Date('1/1/1999 ' + timesl) > new Date('1/1/1999 ' + time))) {
        //break;
        this.timearray.push(this.toggle24hr(timeslot, 0));
      }
      //count++;
    }
    //console.log(this.timearray[count]);
    //console.log(temptimearray.length);
    //console.log(this.timearray.length);

    if (this.timearray.length >= temptimearray.length) {
      this.timearray[0] = 'ASAP (after opening)';
      this.order.slot = "ASAP (after opening)";
    }
  }

  async checkout() {
    if (this.paymode != undefined && this.paymode != null && this.paymode != '') {
      let sessiontoken = this.authService.getSecureToken();
      this.order.sessiontoken = sessiontoken;
      this.order.cartitems = this.cartitems;
      this.order.business_id = await this.customstorageService.getLocalstorage("business_id");
      let addrid = await this.customstorageService.getLocalstorage("selected_addr_id");
      if (addrid != undefined && addrid != null && addrid >= 0) {
        this.order.addressid = addrid
      } else {
        this.order.addressid = 0;
      }
      // this.order.addressid = "1";
      if (this.order.addressid == null) {
      }
      if (this.user.user_id > 0) {
        this.order.addressid = this.addressid;
      }
      console.log(this.order.addressid);
      this.order.delivery_price = this.delivery_charg;
      //this.order.allergy_info = this.allergy;

      this.order.paymode = this.paymode;

      //this.order.slot = this.delv_details.slot;
      //console.log(this.order);

      if (this.user.user_id == 0) {

        this.order.user_type = 'guest';
      }

      // console.log(this.order);
      //this.localSt.store("cartlist", this.cartitems);

      this.create_order();

    } else {

      // this.loginpopS.opencommonpopup("Payment Method", "Please select a payment method", "normal");
      this.paymentmodeerror = true;
    }

  }
  async getAdressDetails() {
    this.addressDetails = await this.customstorageService.getLocalstorage("addressDetails");
    if (this.addressDetails != undefined && this.addressDetails != null) {
      this.showForm = false;
    }
  }
  async saveaddress() {
    if (this.deliveryAddresForm.invalid) {
      this.deliveryAddresForm.controls['name'].markAsTouched();
      this.deliveryAddresForm.controls['email'].markAsTouched();
      this.deliveryAddresForm.controls['mobile_no'].markAsTouched();
      this.deliveryAddresForm.controls['address'].markAsTouched();
      this.deliveryAddresForm.controls['zipcode'].markAsTouched();
      return;
    } else {
      if (this.user.user_id == 0) {
        let addressDetails = this.deliveryAddresForm.value;
        if (this.order.del_mode == 'delivery') {
          let postCode = addressDetails.zipcode.slice(0, -2);
          let delivery_sector = await this.customstorageService.getLocalstorage("delivery_sector");
          if (delivery_sector != undefined && delivery_sector != '' && delivery_sector != null) {
            let postCodeFlag = delivery_sector.includes(postCode.toLowerCase());
            if (postCodeFlag) {
              this.delPostFlag = true;
              this.error = ""
            } else {
              this.error = "The restaurant doesn’t deliver to  " + addressDetails.postCode + ". Enter a different delivery postcode";
            }
          }
          if (this.delPostFlag) {
            this.showForm = false;
            console.log(this.showForm);
            await this.customstorageService.setLocalstorage("addressDetails", addressDetails);
            this.orderBtn = true;
            setTimeout(() => {
              this.getAdressDetails();
            }, 500)
          }
          return;
        }
        this.showForm = false;
        console.log(this.showForm);
        await this.customstorageService.setLocalstorage("addressDetails", addressDetails);
        this.orderBtn = true;
        setTimeout(() => {
          this.getAdressDetails();
        }, 500)
        // return;
        // console.log(addressDetails);return;
        // const md5 = new Md5();
        // const tstring = this.businessid + "bookyupsession" + this.deladdress.name + this.deladdress.email + this.deladdress.mobile_no + this.user.sessiontoken + "xyz#@";
        // // console.log(tstring);
        // this.deladdress.sessiontoken = String(md5.appendStr(tstring).end());

        // this.deladdress.salt_key = this.user.sessiontoken;
        // this.deladdress.user_type = 'guest';

        // this.authService.setSecureToken(this.deladdress.sessiontoken);
        // this.customstorageService.setLocalstorage("usertoken", this.authService.getSecureToken());
      } else {
        this.deladdress.salt_key = "no_salt"
      }
      this.deladdress.delivery_type = await this.customstorageService.getLocalstorage("isDelMode");
      this.showdloader = true;
      this.deladdress.businessid = this.businessid;
      this.deladdress.zipcode = this.deliveryAddresForm.controls['zipcode'].value;
      this.deladdress.address = this.deliveryAddresForm.controls['address'].value;
      this.deladdress.name = this.deliveryAddresForm.controls['name'].value;
      this.deladdress.mobile_no = this.deliveryAddresForm.controls['mobile_no'].value;
      this.deladdress.email = this.deliveryAddresForm.controls['email'].value;
      this.restProvider
        .postdataapi(this.deladdress, "users/order_delivery_address")
        .subscribe((result: any) => {
          this.error = "";
          console.log(result);
          this.showdloader = false;
          if (result.responsecode == 200) {
            this.showAddrInfo = true;
            this.user.address_book = result.address_book;
            this.user.address_book_list = result.address_book_list;
            this.selected_addr_id = result.address_id;
            this.deladdress.address_id = result.address_id;
            this.customstorageService.setLocalstorage("selected_addr_id", result.address_id);
            this.customstorageService.setLocalstorage("deliv_address", this.deladdress);
            this.customstorageService.setLocalstorage("zone_delivery_charg", Number(result.delivery_price));
            this.customstorageService.setLocalstorage("delivery_charge_waiver", Number(result.delivery_charge_waiver));
            // this.cartitems = this.customstorageService.getLocalstorage("cartlist");
            this.customstorageService.setLocalstorage("userdata", this.user);
            if (this.cartitems != null) {
              let total = 0.0;
              for (let i = 0; i < this.cartitems.length; i++) {
                let sum = (
                  this.cartitems[i].product_qnty * this.cartitems[i].sell_price
                ).toFixed(2);
                total = Number(total) + Number(sum);
              }
              total = Number(total.toFixed(2));
              // + additionalchageamnt + servicecharge + baggagecharge) - total_discount
              if ((total + Number(this.service_charge) + Number(this.baggage_charge) + Number(this.service_charge) + Number(result.delivery_price)) >= Number(result.minorder_price)) {
                // this.rts.navigate(["/confirm"]);
                // this.rts.navigate(["order/checkout"]);
                this.showForm = false;
                this.orderBtn = true;
              } else {
                this.error =
                  "Order amount should be equal to or higher than £" +
                  result.minorder_price;
              }
            }
            setTimeout(() => {
              this.initpage();
            }, 500);
          } else {
            this.error = result.status;
          }
        })
      /* .catch((error: any) => {
        // console.log(error);
        this.showdloader = false;
      }); */
    }
  }
  create_order() {
    this.showdloader = true;
    /* if(this.deliveryAddresForm.invalid){
      this.deliveryAddresForm.controls['postCode'].markAsTouched();
      this.deliveryAddresForm.controls['name'].markAsTouched();
      this.deliveryAddresForm.controls['phNo'].markAsTouched();
      this.deliveryAddresForm.controls['email'].markAsTouched();
      this.deliveryAddresForm.controls['address'].markAsTouched();
      return;
    }else{ */
    if (this.user.user_id == 0) {
      this.order.address = this.deliveryAddresForm.value;
    }
    // console.log(JSON.stringify(this.order));return;
    let encryptedpayload = this.encodedecode.newset(JSON.stringify(this.order));
    // let securepayload = { securedata: encryptedpayload }
    let securepayload = this.order;
    this.restProvider
      .postdataapi(securepayload, "business/order")
      .subscribe((result: any) => {
        // console.log(result);
        // this.showdloader = false;
        if (result.responsecode == "200") {
          if (this.user.user_id != 0) {
            this.user.subscribed_bs_list = result.subscribed_bs_list;
            this.customstorageService.setLocalstorage("userdata", this.user);
          }
          if (this.paymode == "cod") {
            window.location.href = this.base_url + "order-complete?v=" + result.id;
          } else if (this.paymode == "emerchantpay") {
            if (result.redirect_payment_url != undefined && result.redirect_payment_url != null && result.redirect_payment_url != '') {
              window.location.href = result.redirect_payment_url;
            } else {
              window.location.href = this.payment_url + "pay?key=" + result.id + "&ptype=" + this.order.paymode;
            }
          } else {
            window.location.href = this.payment_url + "pay?key=" + result.id + "&ptype=" + this.order.paymode;
          }
        } else if (result.responsecode == "202") {
          this.refreshcart();
          this.modalService.open(this.confirmation_modal);

        } else if (result.responsecode == "203") {
          // 203 for cash order otp verify.
          if (this.paymode == "cod") {
            window.location.href = this.base_url + "order/checkout-otp/" + result.id;
          }
        } else if (result.responsecode == "201") {
          this.CustomEventService.opencommonpopup("Error", result.status, "normal");
          this.clearcart();
        } else {
          //console.log(result.status);
          this.CustomEventService.opencommonpopup("Error", result.status, "normal");
        }
        if (this.paymode == "emerchantpay") {
          setTimeout(() => {
            this.showdloader = false;
          }, 5500);
        } else {
          this.showdloader = false;
        }
      })
    /* .catch((er: any) => {
      //console.log("data fetching failed");
      //console.log(er);
      // this.showdata=true;
      this.showdloader = false;
      this.loginpopS.opencommonpopup("Error", "Something went wrong , Try again", "normal");
    }); */
    // }
  }

  showmore() {

    this.showlimit = this.showlimit + 4
  }

  toggle24hr(time: any, onoff: any) {
    if (onoff == undefined) onoff = isNaN(time.replace(':', ''))//auto-detect format
    var pm = time.toString().toLowerCase().indexOf('pm') > -1 //check if 'pm' exists in the time string
    time = time.toString().toLowerCase().replace(/[ap]m/, '').split(':') //convert time to an array of numbers
    time[0] = Number(time[0])
    if (onoff) {//convert to 24 hour:
      if ((pm && time[0] != 12)) time[0] += 12
      else if (!pm && time[0] == 12) time[0] = '00'  //handle midnight
      if (String(time[0]).length == 1) time[0] = '0' + time[0] //add leading zeros if needed
    } else { //convert to 12 hour:
      pm = time[0] >= 12
      if (!time[0]) time[0] = 12 //handle midnight
      else if (pm && time[0] != 12) time[0] -= 12
    }
    return onoff ? time.join(':') : time.join(':') + (pm ? ' pm' : ' am')
  }

  refreshcart() {

    this.showdloader = true;
    let encryptedpayload = this.encodedecode.newset(JSON.stringify(this.order));
    // let securepayload = { securedata: encryptedpayload }
    let securepayload = this.order
    this.restProvider
      .postdataapi(securepayload, "business/order_cart_backend_calculation")
      .subscribe((res: any) => {

        this.showdloader = false;

        // let result = JSON.parse(this.encodedecode.newget(res.data));
        let result = res;
        if (result.responsecode == "200") {
          this.CustomEventService.sethomeredirect(false);
          this.customstorageService.setLocalstorage("scharge", result.service_charge);
          this.customstorageService.setLocalstorage("discount", result.discount);
          this.customstorageService.setLocalstorage("zone_delivery_charg", result.delivery_charge);
          this.customstorageService.setLocalstorage("delivery_charge_waiver", result.delivery_charge_waiver);
          this.customstorageService.setLocalstorage("cartlist", result.cartitems);

          //console.log(result);
        } else {
          //console.log(result.status);
          // this.loginpopS.opencommonpopup("Error", result.status, "normal");
        }

        this.showdloader = false;
      })
      /* .catch((er: any) => {
        //console.log("data fetching failed");
        //console.log(er);
        // this.showdata=true;
        this.showdloader = false;
        // this.loginpopS.opencommonpopup("Error", "Something went wrong , Try again", "normal");
      }) */;


  }

  refreshpage() {

    window.location.reload();
  }

  clearcart() {

    this.customstorageService.clearLocalstorage("isDelMode");
    this.customstorageService.clearLocalstorage("minorderamnt");
    this.customstorageService.clearLocalstorage("zone_delivery_charg");
    this.customstorageService.clearLocalstorage("cartlist");
    this.customstorageService.clearLocalstorage("reorder_order_id");
    this.customstorageService.clearLocalstorage("business_id");
    this.customstorageService.clearLocalstorage("business_timestamp");
    this.customstorageService.clearLocalstorage("scharge");
    this.customstorageService.clearLocalstorage("discount");
    this.customstorageService.clearLocalstorage("freedish");
    this.rts.navigate(["/home"]);
  }
}
