
    <!-- common part for all pages start -->
    <!-- header section start here -->
    <app-header />
    
    <!-- header section end here -->
    <!-- common part for all pages end -->

    <section class="banner">
        <!-- <img class="w-100" src="../../../assets/images/banner/Banner-00.png" alt=""> -->
        <div class="container">
            <div class="search-bar">
                <h1>Tuck into a delicious takeaway today</h1>
                <p>Find the best restaurant near you.</p>
                <div class="input-section">
                    <form>
                        <div class="form-group">
                            <!-- <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Postcode"> -->
                            <input type="text" class="form-control" name="search_by_pin" id="search_by_pin" placeholder="Enter Postcode" (keyup.enter)="goto()" [(ngModel)]="pinid"
                            required />
                            <img src="../../../assets/images/banner/location1.png" alt="">
                        </div>
                        <a href="javascript:void(0)" (click)="goto()">Search</a>
                    </form>
                  
                </div>
            </div>
        </div>
    </section>
    
    

    <section>
        <div class="container">
            <div class="row">
                <div class="col-lg-6">

                </div>
                <div class="col-lg-6">

                </div>
            </div>
        </div>
    </section>


    <!-- footer start here -->
     <app-footer />
    <!-- footer end here -->


    <!-- modal part for all pages start -->
    <!-- modal1-register start here -->
    <!-- <div class="modal fade modal1-register" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLabel">LOG IN</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <h5 class="mb-2">Returning <span>Customer</span></h5>

                    <form>
                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="Email address" id="exampleInputEmail1"
                                aria-describedby="emailHelp">
                        </div>
                        <div class="mb-3">
                            <input type="password" class="form-control" placeholder="Password"
                                id="password">
                        </div>
                        <button type="submit" class="btn">Log In</button>
                        <a href="#">Forget Password?</a>
                    </form>

                    <h5 class="mb-2 mt-2">New <span>Customer</span></h5>
                    <p>Save time now<br>
                        You don't need an account to check out.</p>
                    <a class="btn guest" href="#">Continue as a Guest</a>
                </div>
                <div class="modal-footer">
                    <h6>Or use an account</h6>
                    <div class="lower-div">
                        <a href="#">
                            <img src="../../../assets/images/menu/apple-logo.png" alt="">
                        </a>
                        <a href="#">
                            <img src="../../../assets/images/menu/facebook.png" alt="">
                        </a>
                        <a href="#">
                            <img src="../../../assets/images/menu/google.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- modal1-navigation start here -->

    <!-- modal1-sign-up start here -->
    <!-- <div class="modal fade modal1-register" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h3 class="modal-title" id="exampleModalLabel">SIGN UP</h3>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">

                    <form>
                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="Name*" id="exampleInputEmail1"
                                aria-describedby="emailHelp">
                        </div>
                        <div class="mb-3">
                            <input type="password" class="form-control" placeholder="Phone Number*"
                                id="password">
                        </div>
                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="Email address*"
                                id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>

                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="Password*" id="exampleInputEmail1"
                                aria-describedby="emailHelp">
                        </div>
                        <div class="mb-3">
                            <input type="email" class="form-control" placeholder="Confirm Password*"
                                id="exampleInputEmail1" aria-describedby="emailHelp">
                        </div>
                        <div class="mb-3 form-check">
                            <input type="checkbox" class="form-check-input" id="exampleCheck1">
                            <label class="form-check-label" for="exampleCheck1"> I accept the Terms & Conditions &
                                Privacy Policy</label>
                        </div>
                        <button type="submit" class="btn">Sign Up</button>
                    </form>

                </div>
                <div class="modal-footer">
                    <h6>Or use an account</h6>
                    <div class="lower-div">
                        <a href="#">
                            <img src="../../../assets/images/menu/apple-logo.png" alt="">
                        </a>
                        <a href="#">
                            <img src="../../../assets/images/menu/facebook.png" alt="">
                        </a>
                        <a href="#">
                            <img src="../../../assets/images/menu/google.png" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!-- modal1-sign-up end here -->
    <!-- modal part for all pages end -->









    <!-- <script src="./js/jquery.min.js"></script>
    <script src="./js/bootstrap.bundle.min.js"></script>
    <script src="./js/popper.min.js"></script>
    <script src="./js/bootstrap.min.js"></script>
    <script src="./js/bootstrap4.min.js"></script>
    <script src="./js/owl-carousel-min.js"></script>
    <script src="./js/slick.js"></script>
    <script src="./js/script.js"></script> -->

