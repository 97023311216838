export class OptionModel {

    public optionId: string;
    public optionHeaderLabel: string;
    public optionPrice: number;
    public selection: any


    constructor(obj:any) {

        this.optionId =obj.optionId;
        this.optionHeaderLabel=obj.optionHeaderLabel;
        this.optionPrice=0;
        this.selection=<any>[];
    }

}