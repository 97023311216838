export class SubOptionModel {

    public optionId: string;
    public optionHeaderLabel: string;
    public itemId: string;
    public itemName: string;
    public itemQuantity: number;
    public itemPrice: number;
    // public subSelectionExtras: any;


    constructor(obj: any) {
        this.optionId = obj.optionId;
        this.optionHeaderLabel = obj.optionHeaderLabel;
        this.itemId = obj.itemId;
        this.itemName = obj.itemName;
        this.itemQuantity = obj.itemQuantity;
        this.itemPrice = obj.itemPrice;
        // this.subSelectionExtras = <any>[];
    }

}